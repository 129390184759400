import React from 'react';
import {Spinner} from 'components/Misc/Spinner';
import AppStyles from 'styles/main';

import { Typography} from "antd";

const { Title } = Typography;

const Preview = (props) => {
	return (
		<div>
			<Title level={3} className="title">
				Pré-visualização
			</Title>

			{props.state.preview ? (
				<div
					className="sign-preview"
				>
					<img
						src={props.state.preview}
						alt=""
					/>
				</div>
			) : (
				<div style={{textAlign: 'center', padding: 50}}>
					<Spinner color={AppStyles.colors[1]}/>
				</div>
			)}

			<br/>

			<div className="code">
				<Title level={3} className="title">
					Código para E-mail
				</Title>

				<code>
					{`<img src="${window.api}/signatures/view/${props.params.id}"/>`}
				</code>
			</div>
		</div>
	)
}

export default Preview;