import React from 'react';

function Spinner(props) {
  return (
        <svg width={props.size ? props.size : '38'} height={props.size ? props.size : '38'} style={props.style} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <g transform="translate(1 1)">
                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke={props.color ? props.color : '#2690E2'} strokeWidth="2">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="0.9s"
                            repeatCount="indefinite" />
                    </path>
                </g>
            </g>
        </svg>

    );
}

function SpinnerBold(props) {
  return (
        <svg width="30px" style={{marginLeft: 10}} height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-rolling"><circle cx="50" cy="50" fill="none" ng-attr-stroke="{{config.color}}" ng-attr-stroke-width="{{config.width}}" ng-attr-r="{{config.radius}}" ng-attr-stroke-dasharray="{{config.dasharray}}" stroke="#40CA77" strokeWidth="12" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(91.4313 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.9s" begin="0s" repeatCount="indefinite"></animateTransform></circle></svg>

    );
}



export {
    Spinner,
    SpinnerBold
};
