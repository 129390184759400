import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider } from "antd";
import { Row, Col, Icon, Tabs } from "antd";

// Sections

import {Profile, Company, Plan, Password} from './sections';


// UI

const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

class Settings extends React.Component {

	state = {
		activeKey: 'profile'
	}

	constructor(props) {
	  super(props);
	
	  if(props && props.match.params.tab) {
	  	this.state.activeKey = props.match.params.tab;
	  }
	}

	render() {

		return (
			<Wrapper>
				<WrapperInset>

					<div className={'wrapper-title'}>
						<Title className="title">Configurações</Title>
					</div>

					<Tabs 
					animated={false}
					tabPosition={'top'}
					defaultActiveKey={this.state.activeKey}
					className={'ant-tabs-no-overflow'}
					onChange={(ev) => {
						this.props.history.push('/settings/'+ev);
					}}
					>
					    <TabPane tab="Meu Perfil" key="profile">
					      <Profile/>
					    </TabPane>
					    <TabPane tab="Minha Empresa" key="company">
					      <Company/>
					    </TabPane>
					    <TabPane tab="Meu Plano" key="plan">
					      <Plan/>
					    </TabPane>
					    <TabPane tab="Alterar Senha" key="password">
					      <Password/>
					    </TabPane>
					    
					</Tabs>
				
				
				</WrapperInset>
			</Wrapper>
		);
	}
}
export default Settings;
