import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button } from 'antd';
import {TableView} from './dummy/dummy';

const { Title } = Typography;

class Signs extends React.Component {

	render() {
		return (
			<Wrapper>
				<WrapperInset>
					<div className={'wrapper-title'}>
						<Title className="title">Papel Timbrado</Title>
						<Link to={'legal-paper/create'}>
							<Button type="primary" icon={'plus'}>
								Adicionar
							</Button>
						</Link>
					</div>
					<TableView/>
				</WrapperInset>
			</Wrapper>
				
		)
	}
}

export default Signs;