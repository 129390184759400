import React from 'react';
import { Layout } from 'antd';

import Sider from 'components/Sider/Sider';
import Header from 'components/Header/Header';
import FooterView from 'components/Footer/Footer';

import Routes from '../index';

import 'assets/scss/dashboard.scss';

const { Footer, Content } = Layout;

class Dashboard extends React.Component {

	componentDidMount() {
	        const {bodyClass} = this.props
	        document.querySelector("body").classList.add(bodyClass || "dashboard-view");
	}

	render() {
		return (
				<Layout className="main-wrapper">
			      <Sider
			      history={this.props.history}
			      />
			      <Layout>
			        <Header {...this.props}/>
			        <Content>
			        	<Routes/>
			        </Content>
			        <Footer>
			        	<FooterView/>
			        </Footer>
			      </Layout>
			    </Layout>
		)
	}
}

export default Dashboard;