import axios from 'axios';

const Preview = async (data) => {

	return new Promise(async (resolve, reject) => {
		try {

			let response = await axios.post(`${window.api}/business-cards/preview`, {data: data}, {auth: true});


			if(response && response.data && response.data.result) {
				const {data} = response.data;
				resolve({
					front: response.data.front,
					back: response.data.back
				});
			} else {	
				reject(true);
			}

		} catch (e) {
			reject(true);
		}
	});

}

export {
	Preview
}