import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Upload } from "antd";
import {Spinner} from 'components/Misc/Spinner';
import { Row, Col, Icon, Modal } from "antd";
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {isEmail} from 'validator';
import User from 'stores/user';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from "mobx-react";

const { Option } = Select;
const { Title } = Typography;


@observer
class UserProfile extends React.Component {

	state = {
		data: null,
		loading: false,
		previewImage: false,
		previewImage: ''
	}

	getData = async () => {

		try {

			let response = await axios.get(`${window.api}/user/get`, {auth: true});

			console.log(response);
			
			if(response && response.data && response.data.result) {

				const {data} = response.data;
				this.setState({data: data});

			} else {
				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível carregar as informações no momento.',
					type: 'error',
					confirmButtonText: 'OK'
				});
			}

		} catch (e) {

			Swal.fire({
				title: 'Erro',
				text: 'Não foi possível carregar as informações no momento.',
				type: 'error',
				confirmButtonText: 'OK'
			});

		}

	}

	getBase64 = (file) => {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	  });
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		});
	}


	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	save = async () => {

		const {name, lastName, email, phone} = this.state.data;

		if(!name || name == '') this.sendError('Por favor, informe um nome válido.');
		if(!lastName || lastName == '') this.sendError('Por favor, informe um nome válido.');
		if(!phone || phone == '') this.sendError('Por favor, informe um telefone válido.');
		if(!email || email == '' || !isEmail(email)) this.sendError('Por favor, informe um e-mail válido.');

		let obj = {
			name, 
			lastName, 
			email, 
			phone
		};

		this.setState({loading: true}, async () => {

			try {

				let response = await axios.post(`${window.api}/user/update/profile`, obj, {
				    auth: true
				});

				if(response && response.data && response.data.result) {
					const {data} = response.data;
					User.update(data).then(() => {
						this.setState({loading: false}, () => {
							Swal.fire(
							  'Dados Salvos',
							  'Seus dados foram salvos com sucesso!',
							  'success'
							);
						})
					})

				} else {

					Swal.fire({
						title: 'Erro',
						text: 'Não foi possível carregar as informações no momento.',
						type: 'error',
						confirmButtonText: 'OK'
					});
					
				}


			} catch(e) {

				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível carregar as informações no momento.',
					type: 'error',
					confirmButtonText: 'OK'
				});

			}

		})


	}

	componentDidMount = this.getData();

	avatarChanged = () => {

		if (this.avatarInput.files && this.avatarInput.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e) => {
		      this.imagePreview.open(e);
		    }
		    reader.readAsDataURL(this.avatarInput.files[0]);
		  }
	}

	getFile = () => {
		return this.avatarInput.files[0];
	}


	render() {

		if(!this.state.data) {
			return (<div className="loading-wrapper-inset">
				<Spinner size={50} color={'#EE4540'}/>
			</div>)
		}


		return (
			<div style={{marginTop: 40,}}>

				<Form>

				<Row gutter={40} style={{display: 'flex'}}>
					<Col className="gutter-row" span={12} gutter={32}>
						<div className="gutter-box">

							
								<Title level={3} className="title">
									Dados Pessoais
								</Title>

								<Row gutter={16}>
									<Col span={12}>
										<Form.Item label="Nome">
											<Input 
											size={"large"}
											value={this.getValue('data.name')} 
											id={'name'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Sobrenome">
											<Input 
											size={"large"} 
											value={this.getValue('data.lastName')} 
											id={'lastName'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="E-mail">
											<Input 
											size={"large"} 
											value={this.getValue('data.email')} 
											id={'email'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Telefone">
											<Input 
											size={"large"} 
											value={this.getValue('data.phone')} 
											id={'phone'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
								</Row>


						</div>
					</Col>

					<Col className="gutter-row" span={12} gutter={32}>

						<Title level={3} className="title">
							Imagem do Perfil
						</Title>

						<div className="image-selecter">
							{/*this.state.imagePreview ? (<div className="image-preview"><img src={this.state.imagePreview} alt=""/></div>) : null*/}

							{this.getValue('data.avatar') ? (
								<div className="image-preview"><img src={User.getAvatarURL()} alt=""/></div>
							) : null}

							

							<div className="button-holder">
								<button>Selecionar</button>
								<input 
								type="file" 
								name="avatar" 
								onChange={this.avatarChanged}
								ref={(ref) => this.avatarInput = ref}
								/>
							</div>

							<ImagePreview 
							ref={(ref) => this.imagePreview = ref}
							getFile={this.getFile}
							/>

						</div>

					</Col>


				</Row>

				<Divider/>

				<div style={{textAlign: 'right'}}>
					<Button 
					type="primary" 
					htmlType="submit" 
					onClick={this.save}
					loading={this.state.loading}
					>
					  Salvar
					</Button>
				</div>

				</Form>
			</div>
		);
	}
}



class ImagePreview extends React.Component {

	state = {
		visible: false,
		imageSrc: null,
		loading: false,
		crop: { aspect: 1/1, width: 200, height: 200 }
	}

	open = (e) => {

		let img = new Image();
		img.onload = () => {

			let x = img.width > 200 ? (472/2-100) : 0;
			let y = img.height > 200 ? (472/2-100) : 0;

		 	this.setState(state => {
		 		state.visible = true;
		 		state.imageSrc = e.target.result;
		 		state.crop.x = x;
		 		state.crop.y = y;
		 		return state;
		 	});
		};
		img.src = e.target.result; 

		
	}

	close = () => {
		if(!this.state.loading) {
			this.setState({visible: false},() => {
				this.setState({imageSrc: null})
			});
		}
	}

	done = async () => {


		if(!this.state.loading) {

			this.setState({loading: true}, async () => {

				let fd = new FormData();
				fd.append('avatar', this.props.getFile());
				fd.append('data', JSON.stringify(this.state.crop));


				try {

					let response = await axios.post(`${window.api}/user/update/avatar`, fd, {
					    headers: {
					      'Content-Type': 'multipart/form-data'
					    },
					    auth: true
					});

					if(response && response.data && response.data.result) {
						const {data} = response.data;
						User.update(data).then(() => {

							Swal.fire({
								title: 'Dados Salvos',
								text: 'Seus dados foram salvos com sucesso!',
								type: 'success',
								confirmButtonText: 'OK'
							}).then((result) => {
							  if (result.value) {
							  	this.setState({visible: false, imageSrc: null, loading: false});
							  }
							});

						});
					} else {
						Swal.fire({
							title: 'Erro',
							text: 'Não foi possível carregar as informações no momento.',
							type: 'error',
							confirmButtonText: 'OK'
						});
					}

				} catch(e) {

					Swal.fire({
						title: 'Erro',
						text: 'Não foi possível carregar as informações no momento.',
						type: 'error',
						confirmButtonText: 'OK'
					});

				}
				
			})

		}

	}

	render() {

		return (
				<Modal
			          title="Enviar Avatar"
			          visible={this.state.visible}
			          onOk={this.done}
			          onCancel={this.close}
			          okButtonProps={{loading: this.state.loading}}
			        >
			        
			       {this.state.imageSrc ? (
			       	 <ReactCrop 
			       	 src={this.state.imageSrc} 
			       	 crop={this.state.crop} 
			       	 keepSelection
			       	 onChange={newCrop => this.setState({crop: newCrop})}
			       	 imageStyle={{width: 470, height: 'auto'}}
			       	 />
			       	) : null}

			  </Modal>
		)
	}
}


export default UserProfile;	
