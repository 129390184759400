import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Upload, Descriptions, Badge, Statistic, Tag } from "antd";
import {Spinner} from 'components/Misc/Spinner';
import { Row, Col, Icon, Modal } from "antd";
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {isEmail} from 'validator';
import User from 'stores/user';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from "mobx-react";
import moment from 'moment';

const { Option } = Select;
const { Title, Paragraph } = Typography;



@observer
class UserPlan extends React.Component {

	state = {
		data: null,
		plans: null,
		loading: false
	}

	getData = async () => {
		try {
			let response = await axios.get(`${window.api}/plans`, {auth: true});
			let responsePlan = await axios.get(`${window.api}/user/getPlan`, {auth: true});

			console.log(responsePlan);

			if(response.data && response.data.result && responsePlan.data && responsePlan.data.result) {
				const {plans} = response.data;
				const {data} = responsePlan.data;
				this.setState({data, plans, currentPlan: {...data}});
			}
		} catch (e) {
			Swal.fire({
				title: 'Erro',
				text: 'Não foi possível carregar as informações no momento.',
				type: 'error',
				confirmButtonText: 'OK'
			});
		}
	}


	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		});
	}


	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	updatePlan = async () => {

		const {planId, paymentType} = this.state.data;

		this.setState({loading: true}, async () => {

			try {

				let response = await axios.post(`${window.api}/user/updatePlan`, {planId, paymentType}, {auth: true});

				this.setState({loading: false}, () => {
					if(response && response.data && response.data.result) {

						if(response.data.plan && 
						   response.data.plan.subscriptionRequest && 
						   response.data.plan.subscriptionRequest.subscriptionUrl) {
							window.location.href = response.data.plan.subscriptionRequest.subscriptionUrl;
						} else {
							this.sendError('Não foi possível atualizar seu plano no momento. Por favor, entre em contato com nosso suporte.');
						}
					} else {
						console.log(response);
						this.sendError('Não foi possível atualizar seu plano no momento. Por favor, tente novamente mais tarde.');
					}
				});
			} catch(e) {

				console.log(e);

				this.setState({loading: false}, () => {
					this.sendError('Não foi possível atualizar seu plano no momento. Por favor, tente novamente mais tarde.');
				});
			}
		});

	}

	componentDidMount = this.getData();

	getValue = (path) => {
		return _.get(this.state, path);
	}

	planData = () => {

		const {currentPlan, data} = this.state;
		let plan = this.state.plans.filter(i => i.id === currentPlan.planId)[0];

		let statusColor, status;

		switch(currentPlan.status) {
			case 'ACTIVE':
				status = 'Ativo';
				statusColor = 'success';
				break;
			case 'APPROVAL_PENDING':
				status = 'Aguardando Pagamento';
				statusColor = 'gold';
				break;
			default:
				status = 'Inativo';
				statusColor = 'error';
		}
		let lastPayment = currentPlan.lastPayment ? moment(currentPlan.lastPayment).format('DD/MM/YYYY') : null;
		let paymentTypeLabel = currentPlan.paymentType === 'monthly' ? 'mês' : 'ano';
		let price;

		if(currentPlan.planId === '1') {
			price = 'R$0,00';
		} else {
			price = plan.values[currentPlan.paymentType] ? plan.values[currentPlan.paymentType].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null;
		}
		

		//plan ? plan[planType].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null

		return {
			price,
			name: plan ? plan.name : null,
			status,
			statusColor,
			lastPayment,
			paymentTypeLabel
		}
	}

	newPlanData = () => {
		const {data} = this.state;
		let plan = this.state.plans.filter(i => i.id === data.planId)[0];

		let paymentTypeLabel = data.paymentType === 'monthly' ? 'mês' : 'ano';
		let price = plan.values[data.paymentType] ? plan.values[data.paymentType].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null;

		return {
			price,
			paymentTypeLabel,
			name: plan ? plan.name : null
		}
	}

	didChangePlan = () => {

		const {data, currentPlan} = this.state;
		let plan = this.state.plans.filter(i => i.id === data.planId)[0];

		if(currentPlan.planId === '1') {
			this.setState(state => {
				state.data.paymentType = 'monthly';
				return state;
			})
		}

	}

	isPaidPlan = () => {
		const {data} = this.state;
		if(data.planId === '1') {
			return false;
		} 
		return true;
	}

	isCurrentPlanPaid = () => {
		const {currentPlan} = this.state;
		if(currentPlan.planId === '1') {
			return false;
		} 
		return true;
	}



	render() {

		if(!this.state.data) {
			return (<div className="loading-wrapper-inset">
				<Spinner size={50} color={'#EE4540'}/>
			</div>)
		}

		console.log(this.state);


		return (
			<div style={{marginTop: 40}}>

				<Row gutter={40} style={{display: 'flex'}}>
					<Col className="gutter-row" span={12} gutter={32}>

						<Title level={3} className="title">
							Planos
						</Title>

		

						<Form.Item label="Plano Atual">
							<Select 
							defaultValue={this.getValue('currentPlan.planId')} 
							size={'large'} 
							style={{ width: '100%' }} 
							onChange={(value) => {
								this.setState(state => {
									state.data.planId = value;
									return state;
								}, () => {
									this.didChangePlan()
								});
							}}
							>
								{this.state.plans && 
								 this.state.plans.length && 
								 this.state.plans.map((item, index) => {
								 	return (
								 		<Option value={item.id} key={`plan_select_${index}`}>{item.name}</Option>
								 	)
								 })}
							</Select>
						</Form.Item>

						{this.isPaidPlan() ? (

							<div>
								<Form.Item label="Frequência de Pagamento">
									<Select 
									defaultValue={this.getValue('currentPlan.paymentType') ? this.getValue('currentPlan.paymentType') : 'monthly'} 
									size={'large'} 
									style={{ width: '100%' }} 
									onChange={(value) => {
										this.setState(state => {
											state.data.paymentType = value;
											return state;
										});
									}}
									>
										<Option value={'monthly'}>Mensal</Option>
										<Option value={'yearly'}>Anual <Badge count={'-10%'} /></Option>
									</Select>
								</Form.Item>

								<Form.Item label="Forma de Pagamento">
									<Select defaultValue="paypal" size={'large'} style={{ width: '100%' }}>
										<Option value="paypal">PayPal</Option>
									</Select>
								</Form.Item>
							</div>

						) : null}


						{(this.state.currentPlan.planId != this.state.data.planId) 
					  || (this.state.currentPlan.paymentType && this.state.currentPlan.paymentType != this.state.data.paymentType) ? (

							<div style={{width: '100%', paddingTop: 30}}>
								<Paragraph>
									Deseja alterar seu plano?
								</Paragraph>

								<Row>
									<Col className="gutter-row" span={12} gutter={32}>
										<strong>Plano Atual</strong>
										<Statistic title={this.planData().name} value={`${this.planData().price}/${this.planData().paymentTypeLabel}`} />
									</Col>
									<Col className="gutter-row" span={12} gutter={32}>
										<strong>Novo Plano</strong>
										<Statistic title={this.newPlanData().name} value={`${this.newPlanData().price}/${this.newPlanData().paymentTypeLabel}`} />
									</Col>
								</Row>

								<Paragraph style={{marginTop: 20}}>
									Seu plano só será atualizado após a confirmação do pagamento pela operadora.
									

								</Paragraph>

								<Divider/>

								<div style={{textAlign: 'right'}}>
									<Button 
									type="primary" 
									size={'large'}
									htmlType="submit" 
									onClick={this.updatePlan}
									loading={this.state.loading}
									>
									  Salvar
									</Button>
								</div>


							</div>

						) : null}

						


					</Col>
					<Col className="gutter-row" span={12} gutter={32}>

						<Title level={3} className="title">
							Meu Plano
						</Title>

						{(this.state.currentPlan.paymentStatus === 'PENDING' && this.state.currentPlan.subscriptionRequest) ? (
							<div style={{marginBottom: 20}}>
								<Tag color="red">Aguardando Confirmação de Pagamento</Tag>
							</div>
						) : null}


						<Descriptions bordered>
							<Descriptions.Item label="Plano" span={3}>{this.planData().name}</Descriptions.Item>

							{this.isCurrentPlanPaid() ? (
								<Descriptions.Item label="Valor" span={3}><strong>{this.planData().price}</strong>/{this.planData().paymentTypeLabel}</Descriptions.Item>
							) : null}

							<Descriptions.Item label="Status" span={3}>
							  <Badge status={this.planData().statusColor} text={this.planData().status} />
							</Descriptions.Item>

							{this.isCurrentPlanPaid() ? (
							<Descriptions.Item label="Último Pagamento" span={3}>{this.planData().lastPayment}</Descriptions.Item>
							) : null}

						</Descriptions>

					</Col>
				</Row>

			</div>
		);
	}
}



export default UserPlan;	
