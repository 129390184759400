import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Routes from 'scenes/index.js';
import 'assets/scss/main.scss';

import Login from 'scenes/login/login';
import Main from 'scenes/main/main';
import Vcard from 'scenes/vcard';
import PrivateRoute from 'functions/privateRoute';
import NotFound from 'scenes/not-found';

class App extends React.Component {


	render() {

		return (
			<Router>
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/vcard/:id" component={Vcard} />
					<PrivateRoute path="/" component={Main} />
				</Switch>
			</Router>
		)
	}
}

export default App;
