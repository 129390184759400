const AppStyles = {
    colors: {
    	1: '#EE4540',
    	2: '#C92A42',
    	3: '#801336',
    	4: '#510932',
    	5: '#2C142C',
    	text: '#262626',
    }
}

export default AppStyles;