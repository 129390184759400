import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography, Button } from "antd";
import { TableView } from "./components/table";
import User from "stores/user";
import axios from "axios";
import Swal from 'sweetalert2';
import {Spinner} from 'components/Misc/Spinner';

// CONSTS

const { Title } = Typography;


// CLASS

class Signs extends React.Component {

	state = {
		data: null
	}
	
	getData = async () => {
		try {
			let response = await axios.post(`${window.api}/signatures/get`, {uid: User.get("_id")}, {auth: true});
			
			if(response && response.data && response.data.result) {
				const {data} = response.data;
				this.setState({data: data});
			} else {
				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível carregar as informações no momento.',
					type: 'error',
					confirmButtonText: 'OK'
				});
			}

		} catch(e) {

			Swal.fire({
				title: 'Erro',
				text: 'Não foi possível carregar as informações no momento.',
				type: 'error',
				confirmButtonText: 'OK'
			});

		}
	}

	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	removeItem = async (id) => {
		this.setState({data: null}, async () => {
			try {
				let response = await axios.get(`${window.api}/signatures/remove/${id}`, {auth: true});
				if(response && response.data && response.data.result) {
					this.getData();
				} else {
					this.getData();
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch(e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}

		});
	}

	componentDidMount = async () => this.getData();

	render() {
		return (
			<Wrapper>
				<WrapperInset>
					<div className={"wrapper-title"}>
						<Title className="title">Assinaturas</Title>
						{this.state.data ? (
							<Link to={"email-signatures/create"}>
								<Button type="primary" icon={"plus"}>
									Adicionar
								</Button>
							</Link>
						) : null}
					</div>

					{this.state.data ? (
						<div>
							{this.state.data.length ? (
								<TableView 
								data={this.state.data}
								removeItem={this.removeItem}
								/>
							) : (
								<span>Você ainda não criou nenhuma assinarua. Comece agora clicando no botão <strong>Adicionar</strong>.</span>
							)}
						</div>
					) : (
						<div className="loading-wrapper-inset">
							<Spinner size={50} color={'#EE4540'}/>
						</div>
					)}

					
				</WrapperInset>
			</Wrapper>
		);
	}
}

export default Signs;
