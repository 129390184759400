class signatures {

	static getStatus(status) {

		switch(status) {
			// INATIVO
			case 2:
				return {label: 'Ativo', color: '#48C657'};
				break;
			// ATIVO
			default:
				return {label: 'Ativo', color: '#48C657'}
				break;
		}

	}

}

export {
	signatures
}