import React from "react";
import { Route, Switch } from "react-router-dom";
//import Auth from 'functions/auth';

// Scenes Imports

import * as Dashboard from "./dashboard";
import * as EmailSigns from "./email-signatures";
import * as BusinessCards from "./business-cards";
import * as LegalPaper from "./legal-paper";

import Settings from './settings';
import Payment from './payment';
import NotFound from './not-found';

// Routes Config

const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard.Index
  },
  {
    path: "/email-signatures",
    exact: true,
    component: EmailSigns.List
  },
  {
    path: "/email-signatures/create",
    exact: true,
    component: EmailSigns.Create
  },
  {
    path: "/email-signatures/edit/:id",
    component: EmailSigns.Edit
  },
  {
    path: "/business-cards",
    exact: true,
    component: BusinessCards.List
  },
  {
    path: "/business-cards/edit/:id",
    component: BusinessCards.Edit
  },
  {
    path: "/business-cards/create",
    exact: true,
    component: BusinessCards.Create
  },
  {
    path: "/legal-paper",
    exact: true,
    component: LegalPaper.List
  },
  {
    path: "/legal-paper/edit/:id",
    component: LegalPaper.Edit
  },
  {
    path: "/legal-paper/create",
    exact: true,
    component: LegalPaper.Create
  },
  {
    path: "/settings/:tab?",
    exact: true,
    component: Settings
  },
  {
    path: "/payment/:id",
    exact: true,
    component: Payment
  }
];

// Routes Functions

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact ? true : false}
      render={props => <route.component {...props} routes={route.routes} />}
    />
  );
}

function RouteConfig() {
  let returnArray = [];
  routes.map((route, i) => {
    let routeObj = {
      ...route
    };
    returnArray.push(<RouteWithSubRoutes key={i} {...routeObj} />);
  });

  

  //return returnArray;

  return (
    <Switch>
        {returnArray}
        <Route component={NotFound}/>
    </Switch>
  )
}

export default RouteConfig;
