import React from "react";
import { Row, Col, Icon } from "antd";
import { Typography, Button, Form, Input, Select, Switch, Affix } from "antd";

const { Title } = Typography;
const { Option } = Select;

const FormView = (props) => {

	return (
		<Col className="gutter-row" span={12}>
			<div className="gutter-box">
				<Form>
					<Title level={3} className="title">
						Dados Pessoais
					</Title>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Nome">
								<Input 
								size={"large"} 
								value={props.getValue('data.basicInfo.name')} 
								id={'basicInfo.name'}
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Sobrenome">
								<Input 
								size={"large"} 
								value={props.getValue('data.basicInfo.lastName')} 
								id={'basicInfo.lastName'}
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Celular">
								<Input 
								size={"large"} 
								value={props.getValue('data.contacts.cellphone')} 
								id={'contacts.cellphone'}
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Telefone">
								<Input 
								size={"large"} 
								value={props.getValue('data.contacts.phone')} 
								id={'contacts.phone'}
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
					</Row>

					<br />
					<br />

					<Title level={3} className="title">
						Dados Profissionais
					</Title>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Cargo">
								<Input 
								size={"large"} 
								id={'professional.role'}
								value={props.getValue('data.professional.role')} 
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Setor">
								<Input 
								size={"large"} 
								id={'professional.section'}
								value={props.getValue('data.professional.section')} 
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
					</Row>

					<br />
					<br />

					<Title level={3} className="title">
						Dados de Contato
					</Title>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Site">
								<Input
									size={"large"}
									id={'contacts.site'}
									value={props.getValue('data.contacts.site')} 
									onChange={props.onChangeData}
									prefix={
										<Icon
											type="compass"
											style={{
												color:
													"rgba(0,0,0,.25)"
											}}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="E-mail">
								<Input
									size={"large"}
									id={'contacts.email'}
									value={props.getValue('data.contacts.email')} 
									onChange={props.onChangeData}
									prefix={
										<Icon
											type="mail"
											style={{
												color:
													"rgba(0,0,0,.25)"
											}}
										/>
									}
								/>
							</Form.Item>
						</Col>
						
					</Row>

					<br />
					<br />

					<Title level={3} className="title">
						Endereço
					</Title>

					<Row gutter={16}>
						<Col span={24}>
							<Form.Item label="Endereço Completo">
								<Input 
								size={"large"} 
								value={props.getValue('data.location.address')} 
								id={'location.address'}
								onChange={props.onChangeData}
								/>
							</Form.Item>
						</Col>
					</Row>

					<br />
					<br />

					<Title level={3} className="title">
						Dados de Layout
					</Title>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Layout">
								<Select 
									placeholder="Selecione um Layout" 
									value={props.getValue('data.layout.id')}
									onChange={(value) => {

										props.changeState('layout.id', value).then(() => {
											props.debouncePreview();
										})
										
									}}
									>
										{props.state.layouts && props.state.layouts.length && props.state.layouts.map((item, index) => {
											return (
												<Option value={item.id} key={`layout_${item.id}`}>
													{item.name}
												</Option>
											)
										})}
									</Select>
							</Form.Item>
						</Col>
						
					</Row>

					<br />
					<br />

					<Title level={3} className="title">
						Outras opções
					</Title>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Exibir QR Code">
								<Switch defaultChecked />
							</Form.Item>
						</Col>
					</Row>

					<br />
					<br />

					<Form.Item>
					          <Button 
					          type="primary" 
					          htmlType="submit" 
					          size={'large'}
					          onClick={props.save}
					          loading={props.state.loading}
					          >
					            Salvar
					          </Button>
					</Form.Item>
				</Form>
			</div>
		</Col>
	)

}


export default FormView;