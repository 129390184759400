import {observable, toJS} from 'mobx';
import _ from 'lodash';

class User {
	
	@observable data = null;

	constructor() {
	 
		this.get = (path) => {
		  return _.get(toJS(this.data), path);
		}
		this.set = (path, value) => {
		  return _.set(this.data, path, value);
		}

		this.getAvatarURL = () => {
		  let avatarFilename = this.get('avatar');
		  return `${window.env.cdn}/${avatarFilename}`;
		}

		this.update = async (data) => {
			return new Promise((resolve, reject) => {
				let token = this.get('token');
				this.data = {
					...data,
					token: token
				};
				resolve(true);
			})
		}

		this.getObj = (path) => {
		  return toJS(_.get(this.data, path));
		}

		this.getData = () => {
			return toJS(this.data);
		}
	 
	}

}

const user = new User();
export default user;