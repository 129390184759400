import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography, Radio } from "antd";
import FormView from './components/form';
import {FormValidate} from './functions/validate';
import Swal from 'sweetalert2';
import User from 'stores/user';
import axios from 'axios';
import signatureIcon from 'assets/images/signature-icon.svg';
import { Row, Col, Icon } from "antd";
import "assets/scss/base.scss";
import _ from 'lodash';
import {Preview} from './functions/preview';

const { Title } = Typography;

class Signs extends React.Component {


	state = {
		data: {},
		loading: false,
		preview: null
	}

	constructor(props) {
	  super(props);
	  this.debouncePreview = _.debounce(this.preview, 500);
	}

	componentDidMount = async () => {
	  	
		try {
			let responseLayouts = await axios.get(`${window.api}/business-cards/layouts/`, {auth: true});
			if(responseLayouts 
			   && responseLayouts.data 
			   && responseLayouts.data.result 
			   && responseLayouts.data.data 
			   && responseLayouts.data.data.length) {
				this.setState({layouts: responseLayouts.data.data});
			} else {
				this.sendError('Não foi possível carregar as informações no momento.');
			}
		} catch(e) {
			this.sendError('Não foi possível carregar as informações no momento.');
		}

	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		}, () => {
			this.debouncePreview();
		});
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	preview = () => {
		if(this.shouldShowPreview()) {
			this.setState({preview: null, previewError: null}, () => {
				Preview(this.state.data).then(image => {
					this.setState({preview: image});
				}, () => {
					this.setState({previewError: true});
				});
			});
		}
	}

	changeState = async (path, value) => {
		return new Promise((resolve, reject) => {
			this.setState(state => {
				_.set(state.data, path, value);
				return state;
			}, () => {
				resolve(true);
			});
		})
	}

	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	save = () => {

		const {data} = this.state;
		FormValidate(data).then( async () => {
			try {
				let response = await axios.post(`${window.api}/business-cards/add`, data, {auth: true});

				if(response && response.data && response.data.result) {
					const {id} = response.data;
					this.props.history.push(`/business-cards/edit/${id}`);
				} else {
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch(e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}

		}, (err) => {
			this.sendError(err);
		})
	}

	shouldShowPreview = () => {
		const {data} = this.state;
		FormValidate(data).then( async () => {
			return true;
		}, (err) => {
			return false;
		})
	}


	render() {

		let formViewProps = {
			getValue: this.getValue,
			changeState: this.changeState,
			debouncePreview: this.debouncePreview,
			onChangeData: this.onChangeData,
			save: this.save,
			state: this.state
		};


		return (

				<Wrapper>
					<WrapperInset>
						<div className={"wrapper-title"}>
							<Title className="title">Criar Cartão de Visita</Title>
						</div>

						<Row gutter={24} style={{display: 'flex'}}>
							
							<FormView
							{...formViewProps}
							/>

							<Col className="gutter-row" span={12} gutter={"10"}>
									<Title level={3} className="title">
										Pré-visualização
									</Title>

									{this.shouldShowPreview() ? (
										<div className="biz-card-preview">

											<div className="biz-card-preview-tabs">
												<Radio.Group defaultValue="front" buttonStyle="solid" onChange={(val) => {
													this.setState({previewTab: val.target.value})
												}}>
												  <Radio.Button value="front">Frente</Radio.Button>
												  <Radio.Button value="back">Verso</Radio.Button>
												</Radio.Group>
											</div>

											{this.state.previewTab === 'front' ? (
												<div className="biz-card-preview-tab">
													{(this.state.preview && this.state.preview.front) ? (
														<div className="card-holder">
															<img src={`data:image/png;base64,${this.state.preview.front}`} alt=""/>
														</div>
													) : null}
												</div>
											) : null}
											{this.state.previewTab === 'back' ? (
												<div className="biz-card-preview-tab">
													{(this.state.preview && this.state.preview.back) ? (
														<div className="card-holder">
															<img src={`data:image/png;base64,${this.state.preview.back}`} alt=""/>
														</div>
													) : null}
												</div>
											) : null}

											<div className='biz-card-action-button'>
												<span>Você poderá baixar este cartão após salvá-lo.</span>
											</div>

										</div>
									) : (
										<div className="waitForPreview">
											<img src={signatureIcon} alt=""/>
											<span>Por favor, preencha os dados antes de pré visualizar seu cartão.</span>
										</div>
									)}

									
							</Col>
						</Row>
					</WrapperInset>
				</Wrapper>

		);
	}
}

export default Signs;
