import React from "react";


// FUNCTIONS
import {FormValidate} from './functions/validate';
import {Preview} from './functions/preview';
import _ from 'lodash';
import Swal from 'sweetalert2';
import User from 'stores/user';
import axios from 'axios';


// UI
import { Row, Col, Icon } from "antd";
import AppStyles from 'styles/main';
import {Spinner} from 'components/Misc/Spinner';
import FormView from './components/form';
import PreviewView from './components/preview';
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography } from "antd";

// CSS
import "assets/scss/base.scss";
import "assets/scss/email-signatures.scss";

// UI COMP.
const { Title } = Typography;

class Signs extends React.Component {

	state = {
		data: null,
		loading: false,
		preview: null
	}

	constructor(props) {
	  super(props);
	  this.debouncePreview = _.debounce(this.preview, 500);
	}

	getData = async () => {

		const {params} = this.props.match;
		const {id} = params;


		if(id) {
			try {
				let responseLayouts = await axios.get(`${window.api}/signatures/layouts/`, {auth: true});
				let response = await axios.post(`${window.api}/signatures/get/${id}`, {uid: User.get("_id")}, {auth: true});
				
				if(responseLayouts 
				   && responseLayouts.data 
				   && responseLayouts.data.result 
				   && responseLayouts.data.data 
				   && responseLayouts.data.data.length 
				   && response 
				   && response.data 
				   && response.data.result) {

					const {data} = response.data;

					this.setState({data: data, layouts: responseLayouts.data.data}, () => {
						this.preview();
					});

				} else {
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch (e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}
		} else {
			this.sendError('Não foi possível carregar as informações no momento.');
		}

	}

	preview = () => {


		this.setState({preview: null, previewError: null}, () => {
			Preview(this.state.data).then(image => {
				this.setState({preview: `data:image/png;base64,${image}`});
			}, () => {
				this.setState({previewError: true});
			});
		});
	}

	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		}, () => {
			this.debouncePreview();
		});
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	save = async () => {

		const {data} = this.state;
		
		FormValidate(data).then(() => {

			this.setState({loading: true}, async () => {
					const {params} = this.props.match;
					try {
						let response = await axios.post(`${window.api}/signatures/change/${params.id}`, data, {auth: true});
						console.log(response);
						if(response && response.data && response.data.result) {
							
							this.setState({loading: false}, () => {
								Swal.fire(
								  'Dados Salvos',
								  'Seus dados foram salvos com sucesso!',
								  'success'
								);
							})

						} else {
							this.setState({loading: false}, () => {
								Swal.fire({
									title: 'Erro',
									text: 'Não foi possível salvar as informações no momento.',
									type: 'error',
									confirmButtonText: 'OK'
								});
							})
						}
					} catch(e) {
						this.setState({loading: false}, () => {
							Swal.fire({
								title: 'Erro',
								text: 'Não foi possível salvar as informações no momento.',
								type: 'error',
								confirmButtonText: 'OK'
							});
						})
					}
			});
		}, (error) => {
			this.sendError(error);
		})
		
	}

	changeState = async (path, value) => {
		return new Promise((resolve, reject) => {
			this.setState(state => {
				_.set(state.data, path, value);
				return state;
			}, () => {
				resolve(true);
			});
		})
	}

	componentDidMount = this.getData();


	render() {

		if(!this.state.data) {
			return (
				<Wrapper>
					<WrapperInset>
						<div className="pageLoaderWrapper">
							<Spinner color={AppStyles.colors[1]}/>
						</div>
					</WrapperInset>
				</Wrapper>
			)
		}


		let formViewProps = {
			getValue: this.getValue,
			changeState: this.changeState,
			debouncePreview: this.debouncePreview,
			onChangeData: this.onChangeData,
			state: this.state,
			save: this.save
		};

		return (

				<Wrapper>
					<WrapperInset>
						<div className={"wrapper-title"}>
							<Title className="title">Editar Assinatura</Title>
						</div>

						<Row gutter={24} style={{display: 'flex'}}>
							<Col className="gutter-row" span={12}>
								<div className="gutter-box">
									
									<FormView
										{...formViewProps}
									/>

								</div>
							</Col>

							<Col className="gutter-row" span={12} gutter={"30"}>
									<PreviewView
									state={this.state}
									params={this.props.match.params}
									/>
							</Col>
						</Row>
					</WrapperInset>
				</Wrapper>

		);
	}
}

export default Signs;
