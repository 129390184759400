import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography, Button, Form, Input, Select, Switch, Affix } from "antd";

import { Row, Col, Icon } from "antd";
import "assets/scss/base.scss";
import "assets/scss/email-signatures.scss";

const { Title } = Typography;
const { Option } = Select;

class Signs extends React.Component {
	render() {

		return (

				<Wrapper>
					<WrapperInset>
						<div className={"wrapper-title"}>
							<Title className="title">Editar Papel Timbrado</Title>
						</div>

						<Row gutter={24} style={{display: 'flex'}}>
							<Col className="gutter-row" span={12}>
								<div className="gutter-box">
									<Form>
										<Title level={3} className="title">
											Dados Pessoais
										</Title>

										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Nome">
													<Input size={"large"} />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Sobrenome">
													<Input size={"large"} />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Celular">
													<Input size={"large"} />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Telefone">
													<Input size={"large"} />
												</Form.Item>
											</Col>
										</Row>

										<br />
										<br />

										<Title level={3} className="title">
											Dados Profissionais
										</Title>

										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Cargo">
													<Input size={"large"} />
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Setor">
													<Input size={"large"} />
												</Form.Item>
											</Col>
										</Row>

										<br />
										<br />

										<Title level={3} className="title">
											Dados de Contato
										</Title>

										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Site">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="compass"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="E-mail">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="mail"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="LinkedIn">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="linkedin"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Facebook">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="facebook"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Twitter">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="twitter"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Instagram">
													<Input
														size={"large"}
														prefix={
															<Icon
																type="instagram"
																style={{
																	color:
																		"rgba(0,0,0,.25)"
																}}
															/>
														}
													/>
												</Form.Item>
											</Col>
										</Row>

										<br />
										<br />

										<Title level={3} className="title">
											Endereço
										</Title>

										<Row gutter={16}>
											<Col span={24}>
												<Form.Item label="Nome do Endereço">
													<Input size={"large"} />
												</Form.Item>
											</Col>
											<Col span={24}>
												<Form.Item label="Endereço Completo">
													<Input size={"large"} />
												</Form.Item>
											</Col>
										</Row>

										<br />
										<br />

										<Title level={3} className="title">
											Dados de Layout
										</Title>

										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Layout">
													<Select placeholder="Selecione um Layout">
														<Option value="jack">
															Layout 1
														</Option>
														<Option value="lucy">
															Layout 2
														</Option>
														<Option value="tom">
															Layout 3
														</Option>
													</Select>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item label="Identidade Visual">
													<Select placeholder="Selecione uma Identidade">
														<Option value="jack">
															Minha Empresa
														</Option>
														<Option value="lucy">
															Customizado
														</Option>
													</Select>
												</Form.Item>
											</Col>
										</Row>

										<br />
										<br />

										<Title level={3} className="title">
											Outras opções
										</Title>

										<Row gutter={16}>
											<Col span={12}>
												<Form.Item label="Exibir QR Code">
													<Switch defaultChecked />
												</Form.Item>
											</Col>
										</Row>
									</Form>
								</div>
							</Col>

							<Col className="gutter-row" span={12} gutter={"10"}>
									<Title level={3} className="title">
										Pré-visualização
									</Title>

										<div
											className="sign-preview"
										>
											<img
												src={
													"http://redti.com.br/assinaturas/assinatura/6"
												}
												alt=""
											/>
										</div>
							</Col>
						</Row>
					</WrapperInset>
				</Wrapper>

		);
	}
}

export default Signs;
