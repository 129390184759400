import axios from 'axios';
import User from 'stores/user';

axios.interceptors.request.use(function (config) {
	if(config.auth) {
		let token = User.get('token');
		config.auth = null;
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
