import React from "react";
import { Typography, Button, Form, Input, Select, Switch, Affix } from "antd";
import { Row, Col, Icon } from "antd";
import _ from 'lodash';

const { Title } = Typography;
const { Option } = Select;

const FormView = (props) => {


	return (
		<Form>
			<Title level={3} className="title">
				Dados Pessoais
			</Title>

			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Nome">
						<Input 
						size={"large"} 
						value={props.getValue('data.basicInfo.name')} 
						id={'basicInfo.name'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Sobrenome">
						<Input 
						size={"large"}
						value={props.getValue('data.basicInfo.lastName')} 
						id={'basicInfo.lastName'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Celular">
						<Input 
						size={"large"} 
						value={props.getValue('data.contacts.cellphone')} 
						id={'contacts.cellphone'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Telefone">
						<Input 
						size={"large"} 
						value={props.getValue('data.contacts.phone')} 
						id={'contacts.phone'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
			</Row>

			<br />
			<br />

			<Title level={3} className="title">
				Dados Profissionais
			</Title>

			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Cargo">
						<Input 
						size={"large"}
						value={props.getValue('data.professional.role')} 
						id={'professional.role'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Setor">
						<Input 
						size={"large"} 
						value={props.getValue('data.professional.section')} 
						id={'professional.section'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
			</Row>

			<br />
			<br />

			<Title level={3} className="title">
				Dados de Contato
			</Title>

			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Site">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.site')} 
							id={'contacts.site'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="compass"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="E-mail">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.email')} 
							id={'contacts.email'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="mail"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="LinkedIn">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.linkedin')} 
							id={'contacts.linkedin'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="linkedin"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Facebook">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.facebook')} 
							id={'contacts.facebook'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="facebook"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Twitter">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.twitter')} 
							id={'contacts.twitter'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="twitter"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Instagram">
						<Input
							size={"large"}
							value={props.getValue('data.contacts.instagram')} 
							id={'contacts.instagram'} 
							onChange={props.onChangeData}
							prefix={
								<Icon
									type="instagram"
									style={{
										color:
											"rgba(0,0,0,.25)"
									}}
								/>
							}
						/>
					</Form.Item>
				</Col>
			</Row>

			<br />
			<br />

			<Title level={3} className="title">
				Endereço
			</Title>

			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Nome do Endereço">
						<Input 
						size={"large"}
						value={props.getValue('data.location.name')} 
						id={'location.name'} 
						onChange={props.onChangeData}
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item label="Endereço Completo">
						<Input 
						size={"large"} 
						value={props.getValue('data.location.address')} 
						id={'location.address'} 
						onChange={props.onChangeData}

						/>
					</Form.Item>
				</Col>
			</Row>

			<br />
			<br />

			<Title level={3} className="title">
				Dados de Layout
			</Title>

			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Layout">
						<Select 
						placeholder="Selecione um Layout" 
						value={props.getValue('data.layout.id')}
						onChange={(value) => {

							console.log(props);

							props.changeState('layout.id', value).then(() => {
								props.debouncePreview();
							})
							
						}}
						>
							{props.state.layouts && props.state.layouts.length && props.state.layouts.map((item, index) => {
								return (
									<Option value={item.id} key={`layout_${item.id}`}>
										{item.name}
									</Option>
								)
							})}
						</Select>
					</Form.Item>
				</Col>
			
			</Row>

			<br />
			<br />

			<Title level={3} className="title">
				Outras opções
			</Title>

			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Exibir QR Code">
						<Switch 
							defaultChecked
							checked={props.getValue('data.layout.showQrCode')}
							onChange={(event) => {
								props.changeState('layout.showQrCode', event).then(() => {
									props.debouncePreview();
								})
							}}
						/>
						
					</Form.Item>
				</Col>
				<Col span={12}>
				{props.create ? (<div><span>O seu QR Code assim como seu Vcard somente estará ativo após salvar a assinatura.</span></div>) : null}
				</Col>
			</Row>

			<br />
			<br />

			<Form.Item>
			          <Button 
			          type="primary" 
			          htmlType="submit" 
			          size={'large'}
			          onClick={props.save}
			          loading={props.state.loading}
			          >
			            Salvar
			          </Button>
			</Form.Item>
		</Form>
	)
}

export default FormView;