import React from 'react';
import _ from 'lodash';
import { Link, NavLink } from "react-router-dom";
import 'assets/scss/dashboard.scss';
import { Layout, Menu, Icon } from 'antd';
import {production, development} from './Menu';

const { Sider } = Layout;
const { SubMenu }  = Menu;


class SiderView extends React.Component {

	state = { 
		selectedRoute: ['dashboard'],
		defaultOpenKeys: [],
		menu: process.env.REACT_APP_TYPE === 'development' ? development : production
	}

	handleClick = () => {

	}

	setActiveRoute = () => {
		const {pathname} = this.props.history.location;
		let state = {
			selectedRoute: [pathname]
		};
		let index = _.filter(this.state.menu, function(item) {
			if(item.items && item.items.length) {
				let hasThisPath = _.filter(item.items, {path: pathname});
				if(hasThisPath.length) {
					return item;
				}
			}
		});
		if(index.length) {
			state.defaultOpenKeys = [index[0].key];
		}
	    this.setState(state);
	}

	componentWillMount() {
	  this.setActiveRoute();
	}

	componentDidMount() {
      this.props.history.listen(() => {
        this.setActiveRoute();
      });
	}

	render() {

		return (
	      <Sider
	      width={280}
	      className={'sider'}
	      >
	      <div className="logo"><img src={require('assets/images/sider-logo.svg')} alt=""/></div>

      		<Menu
              onClick={this.handleClick}
              defaultOpenKeys={this.state.defaultOpenKeys}
              selectedKeys={this.state.selectedRoute}
              mode="inline"
              className={'main-menu'}
            >
            	{this.state.menu.map((item, index) => {

            		if(item.items && item.items.length) {
            			return (
            						<SubMenu
            				          key={item.key}
            				          title={
            				            <span>
            				              <Icon type={item.icon}/>
            				              <span>{item.name}</span>
            				            </span>
            				          }
            				        >
            				        {item.items && item.items.length && item.items.map((subitem, subindex) => {
            				        	//item.key+'-'+subitem.key
            				        	return (
            				        		<Menu.Item key={subitem.key}>
            				        			<Link to={subitem.path}>{subitem.name}</Link>
            				        		</Menu.Item>
            				        	)
            				        })}
            				        </SubMenu>	
            			)
            		} else {
            			return (
            				<Menu.Item 
            				key={item.key}
            				>
            					  <Icon type={item.icon}/>
            					  <Link to={item.path}>{item.name}</Link>
            				</Menu.Item>
            			)
            		}
            	})}
            </Menu>

 			<div className="end-view">
 				<Link to={'http://pixelmaker.co/terms'}>Termos de Uso</Link>
 				<Link to={'http://pixelmaker.co/privacy'}>Política de Privacidade</Link>
 			</div>

	      </Sider>
		)
	}
}

export default SiderView;