import React from "react";

import { Form, Icon, Input, Button, Checkbox } from "antd";
import "assets/scss/login.scss";
import isEmail from "validator/lib/isEmail";
import Swal from "sweetalert2";
import Auth from "functions/auth";
import axios from "axios";

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			data: {}
		};
	}

	componentDidMount() {
		console.log(window.api);
	}

	login = () => {
		if (!this.state.data.email || !isEmail(this.state.data.email)) {
			Swal.fire({
				title: "Erro",
				text: "Por favor, informe um e-mail válido",
				type: "error",
				confirmButtonText: "OK"
			});
			return;
		}

		if (!this.state.data.password) {
			Swal.fire({
				title: "Erro",
				text: "Por favor, informe uma senha válida",
				type: "error",
				confirmButtonText: "OK"
			});
			return;
		}

		this.setState({ loading: true }, () => {
			axios
				.post(`${window.api}/auth`, this.state.data)
				.then(response => {
					if (response.data) {
						if (response.data.result) {
							const { result, __v, ...data } = response.data;
							Auth.set(data).then(() => {
								setTimeout(() => {
									this.props.history.replace("/");
								}, 1000);
							});
						} else {
							this.setState({ loading: false }, () => {
								Swal.fire({
									title: "Erro",
									text: response.data.message,
									type: "error",
									confirmButtonText: "OK"
								});
							});
						}
					} else {
						this.setState({ loading: false }, () => {
							Swal.fire({
								title: "Erro",
								text:
									"Não conseguimos fazer o login no momento. Por favor, tente novamente mais tarde.",
								type: "error",
								confirmButtonText: "OK"
							});
						});
					}
				})
				.catch(error => {
					this.setState({ loading: false }, () => {
						Swal.fire({
							title: "Erro",
							text:
								"Não conseguimos fazer o login no momento. Por favor, tente novamente mais tarde.",
							type: "error",
							confirmButtonText: "OK"
						});
					});

					console.log(error);
				});
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;

		return (
			<div className="login">
				<div className="hero" />
				<div className="login-wrapper">
					<div className="login-inset">
						<div className="logo-wrapper">
							<img
								src={require("assets/images/login-logo.svg")}
								alt=""
							/>
						</div>

						<Form
							onSubmit={this.handleSubmit}
							layout={"vertical"}
							className="login-form"
						>
							<Form.Item>
								{getFieldDecorator("username", {
									rules: [
										{
											required: true,
											message:
												"Por favor, insira um e-mail."
										}
									]
								})(
									<Input
										prefix={
											<Icon
												type="mail"
												style={{
													color: "rgba(0,0,0,.25)"
												}}
											/>
										}
										placeholder="E-mail"
										size="large"
										onChange={event => {
											const { value } = event.target;
											this.setState(state => {
												state.data.email = value;
												return state;
											});
										}}
									/>
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator("password", {
									rules: [
										{
											required: true,
											message:
												"Por favor, insira uma senha."
										}
									]
								})(
									<Input
										prefix={
											<Icon
												type="lock"
												style={{
													color: "rgba(0,0,0,.25)"
												}}
											/>
										}
										type="password"
										placeholder="Senha"
										size="large"
										onChange={event => {
											const { value } = event.target;
											this.setState(state => {
												state.data.password = value;
												return state;
											});
										}}
									/>
								)}
							</Form.Item>
							<Form.Item>
								{getFieldDecorator("remember", {
									valuePropName: "checked",
									initialValue: true
								})(<Checkbox>Lembrar meu login</Checkbox>)}
								<a className="login-form-forgot" href="">
									Esqueci minha senha
								</a>
							</Form.Item>

							<Button
								size={"large"}
								loading={this.state.loading}
								onClick={this.login}
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Continuar
							</Button>
						</Form>
					</div>
				</div>
			</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(Login);

export default WrappedNormalLoginForm;
