import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button } from 'antd';
import {TableView} from './components/table';
import Swal from 'sweetalert2';
import {Spinner} from 'components/Misc/Spinner';
import axios from 'axios';
import User from "stores/user";

const { Title } = Typography;

class Signs extends React.Component {

	state = {
		data:null,
		loading: true
	}

	getData = async () => {

		try {
			let response = await axios.post(`${window.api}/business-cards/get`, {uid: User.get("_id")}, {auth: true});
			
			if(response && response.data && response.data.result) {
				const {data} = response.data;
				this.setState({data: data});
			} else {
				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível carregar as informações no momento.',
					type: 'error',
					confirmButtonText: 'OK'
				});
			}

		} catch(e) {
			Swal.fire({
				title: 'Erro',
				text: 'Não foi possível carregar as informações no momento.',
				type: 'error',
				confirmButtonText: 'OK'
			});
		}

	}

	componentDidMount = this.getData();

	removeItem = async (id) => {
		this.setState({data: null}, async () => {
			try {
				let response = await axios.get(`${window.api}/business-cards/remove/${id}`, {auth: true});
				if(response && response.data && response.data.result) {
					this.getData();
				} else {
					this.getData();
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch(e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}

		});
	}

	render() {



		return (
			<Wrapper>
				<WrapperInset>
					<div className={'wrapper-title'}>
						<Title className="title">Cartões de Visita</Title>
						{this.state.data ? (
							<Link to={'business-cards/create'}>
								<Button type="primary" icon={'plus'}>
									Adicionar
								</Button>
							</Link>
						) : null}
					</div>

					{this.state.data ? (
						<TableView
						data={this.state.data}
						removeItem={this.removeItem}
						/>
					) : (
						<div className="loading-wrapper-inset">
							<Spinner size={50} color={'#EE4540'}/>
						</div>
					)}

					
				</WrapperInset>
			</Wrapper>
				
		)
	}
}

export default Signs;