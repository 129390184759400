import axios from 'axios';

const Preview = async (data) => {

	return new Promise(async (resolve, reject) => {
		try {

			console.log('data', data);

			let response = await axios.post(`${window.api}/signatures/preview`, {data: data}, {auth: true});
			
			console.log(response);

			if(response && response.data && response.data.result) {
				const {data} = response.data;
				resolve(data);
			} else {	
				reject(true);
			}

		} catch (e) {
			reject(true);
		}
	});

}

export {
	Preview
}