import React from 'react';
import packageJson from '../../../package.json';
import 'assets/scss/footer.scss';

function Footer() {

	let year = new Date().getFullYear();
	let version = packageJson.version;

	return (
		<div className="footer-view">
			<span>Copyright © Pixelmaker {year}. Todos os direitos reservados.</span>
			<span className="version">v {version}</span>
		</div>
	);
}

export default Footer;