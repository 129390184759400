import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Result } from "antd";
import axios from 'axios';

const { Title } = Typography;



class NotFound extends React.Component {


	render() {

		return (
			<div>
				<Result
				  status="404"
				  title="Ops!"
				  subTitle="A página que você procura não existe."
				  extra={<Button type="primary" href="/">Voltar ao Painel</Button>}
				/>
			</div>
		);
	}
}
export default NotFound;
