import React from "react";

export const IconCellphone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="22"
      viewBox="0 0 14 22"
    >
      <path
        fill="#D7BCC0"
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        d="M878.537 460h-11.074c-.807 0-1.463.64-1.463 1.428v19.144c0 .787.656 1.428 1.463 1.428h11.074c.807 0 1.463-.64 1.463-1.428v-19.144c0-.788-.656-1.428-1.463-1.428zM873 480.523a1.032 1.032 0 01-1.044-1.019c0-.563.467-1.02 1.044-1.02s1.044.457 1.044 1.02-.467 1.02-1.044 1.02zm5.02-4.22a.525.525 0 01-.532.518h-8.976a.525.525 0 01-.532-.519v-14.01c0-.287.238-.52.532-.52h8.976c.294 0 .532.233.532.52v14.01z"
        transform="translate(-866 -460)"
      ></path>
    </svg>
  );
}

export const IconPhone = () => {
	return (
		<svg
		     xmlns="http://www.w3.org/2000/svg"
		     width="20"
		     height="20"
		     viewBox="0 0 20 20"
		   >
		     <path
		       fill="#D7BCC0"
		       fillRule="nonzero"
		       stroke="none"
		       strokeWidth="1"
		       d="M589.957 477.496c-.056-.17-.416-.421-1.08-.752a33.15 33.15 0 00-.766-.427 71.52 71.52 0 01-.902-.497c-.27-.151-.523-.298-.76-.44a18.66 18.66 0 00-.355-.249c-.199-.137-.367-.239-.504-.305a.93.93 0 00-.405-.1c-.19 0-.426.136-.71.405-.284.27-.545.564-.781.881-.237.318-.488.61-.753.88s-.483.406-.653.406a.966.966 0 01-.32-.071 3.313 3.313 0 01-.292-.12c-.066-.034-.18-.1-.34-.2a8.771 8.771 0 00-.27-.163c-1.298-.72-2.41-1.544-3.339-2.472-.927-.928-1.751-2.04-2.471-3.338a8.227 8.227 0 00-.164-.27 5.122 5.122 0 01-.199-.34 3.078 3.078 0 01-.12-.292.961.961 0 01-.071-.32c0-.17.135-.388.405-.653s.563-.516.88-.753c.318-.236.611-.497.881-.78.27-.285.405-.521.405-.71a.927.927 0 00-.1-.406 3.948 3.948 0 00-.305-.504c-.137-.199-.22-.317-.249-.355-.142-.237-.288-.49-.44-.76a71.71 71.71 0 01-.497-.902 33.78 33.78 0 00-.426-.767c-.332-.663-.582-1.023-.753-1.08a.796.796 0 00-.298-.042c-.256 0-.59.047-1.002.142a5.626 5.626 0 00-.973.298c-.473.2-.975.777-1.506 1.733-.483.89-.724 1.77-.724 2.642 0 .255.017.504.05.746.033.241.092.513.177.816.086.303.154.529.206.675.052.147.15.41.291.788.142.38.228.611.256.696.331.928.724 1.757 1.179 2.486.748 1.212 1.768 2.465 3.06 3.757 1.294 1.293 2.546 2.313 3.758 3.061.73.455 1.558.848 2.486 1.18.085.028.317.113.696.255.379.142.642.24.788.291.147.052.372.121.675.207.303.085.575.144.817.177s.49.05.746.05c.87 0 1.752-.242 2.642-.724.956-.53 1.534-1.033 1.733-1.506.104-.237.203-.561.298-.973.095-.412.142-.746.142-1.002a.792.792 0 00-.043-.299z"
		       transform="translate(-570 -462)"
		     ></path>
		   </svg>
	)
}

const Twitter = () => {
	return (
		<svg
		      xmlns="http://www.w3.org/2000/svg"
		      width="23"
		      height="19"
		      viewBox="0 0 23 19"
		    >
		      <path
		        fill="#EE4540"
		        fillRule="nonzero"
		        stroke="none"
		        strokeWidth="1"
		        d="M971 669.248a9.3 9.3 0 01-2.71.756 4.805 4.805 0 002.075-2.653 9.416 9.416 0 01-2.998 1.165 4.669 4.669 0 00-3.444-1.516c-2.606 0-4.718 2.149-4.718 4.797 0 .376.041.743.122 1.093-3.921-.2-7.398-2.11-9.725-5.013a4.824 4.824 0 00-.638 2.412c0 1.665.834 3.133 2.099 3.993a4.659 4.659 0 01-2.137-.602v.06c0 2.324 1.627 4.263 3.784 4.705a4.704 4.704 0 01-1.243.168c-.305 0-.6-.03-.889-.09.601 1.908 2.343 3.295 4.407 3.333a9.366 9.366 0 01-5.86 2.05c-.38 0-.756-.022-1.125-.065a13.176 13.176 0 007.232 2.159c8.68 0 13.424-7.311 13.424-13.652l-.016-.621a9.511 9.511 0 002.36-2.479z"
		        transform="translate(-948 -667)"
		      ></path>
		    </svg>
	)
}

const Instagram = () => {
	return (
		<svg
		      xmlns="http://www.w3.org/2000/svg"
		      width="20"
		      height="20"
		      viewBox="0 0 20 20"
		    >
		      <path
		        fill="#EE4540"
		        fillRule="nonzero"
		        stroke="none"
		        strokeWidth="1"
		        d="M5.52 0h8.96a5.526 5.526 0 015.516 5.303l.004.216v8.962c0 2.97-2.36 5.4-5.303 5.515l-.216.004H5.519c-2.97 0-5.4-2.36-5.515-5.303L0 14.481V5.519C0 2.55 2.36.12 5.303.004L5.519 0h8.962zm8.96 1.774H5.52a3.75 3.75 0 00-3.74 3.547l-.006.198v8.962a3.75 3.75 0 003.547 3.74l.198.005h8.962a3.75 3.75 0 003.74-3.547l.005-.198V5.519a3.75 3.75 0 00-3.745-3.745zM10 5c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5 2.243-5 5-5zm0 1.722A3.282 3.282 0 006.722 10 3.282 3.282 0 0010 13.278 3.282 3.282 0 0013.278 10 3.282 3.282 0 0010 6.722zM15.5 3c.395 0 .783.16 1.06.44.28.277.44.665.44 1.06 0 .394-.16.781-.44 1.06-.279.28-.665.44-1.06.44-.394 0-.781-.16-1.06-.44-.28-.279-.44-.666-.44-1.06 0-.395.16-.783.44-1.06.279-.28.666-.44 1.06-.44z"
		        transform="translate(-1016 -666) translate(1016 666)"
		      ></path>
		    </svg>
	)
}

const LinkedIn = () => {
	return (
		<svg
		     xmlns="http://www.w3.org/2000/svg"
		     width="21"
		     height="20"
		     viewBox="0 0 21 20"
		   >
		     <path
		       fill="#EE4540"
		       fillRule="nonzero"
		       stroke="none"
		       strokeWidth="1"
		       d="M1006 678.264V686h-4.503v-7.222c0-1.816-.649-3.05-2.278-3.05-1.246 0-1.981.836-2.31 1.64-.118.29-.153.695-.153 1.095V686h-4.499s.06-12.227 0-13.493h4.5v1.912c-.007.016-.02.03-.026.047h.026v-.047c.6-.919 1.665-2.229 4.057-2.229 2.968 0 5.186 1.929 5.186 6.075zM987.547 666c-1.539 0-2.547 1.006-2.547 2.334 0 1.291.978 2.332 2.49 2.332h.026c1.574 0 2.548-1.04 2.548-2.332-.03-1.328-.974-2.334-2.517-2.334zm-2.278 20h4.5v-13.495h-4.5V686z"
		       transform="translate(-985 -666)"
		     ></path>
		   </svg>
	)
}

const Facebook = () => {
	return (
		<svg
		      xmlns="http://www.w3.org/2000/svg"
		      width="11"
		      height="20"
		      viewBox="0 0 11 20"
		    >
		      <path
		        fill="#EE4540"
		        fillRule="nonzero"
		        stroke="none"
		        strokeWidth="1"
		        d="M927.586 666.004l-2.639-.004c-2.964 0-4.88 1.932-4.88 4.922v2.27h-2.652a.411.411 0 00-.415.407v3.289c0 .225.186.407.415.407h2.653v8.297c0 .226.185.408.415.408h3.46c.23 0 .416-.183.416-.408v-8.297h3.101c.23 0 .415-.182.415-.407l.001-3.289a.405.405 0 00-.121-.288.419.419 0 00-.294-.12h-3.102v-1.923c0-.925.224-1.394 1.449-1.394l1.777-.001a.411.411 0 00.415-.408v-3.053a.411.411 0 00-.414-.408z"
		        transform="translate(-917 -666)"
		      ></path>
		    </svg>
	)
}

export default function Icon(props) {

	switch(props.type) {
		case 'phone':
			return <IconPhone/>
			break;
		case 'cellphone':
			return <IconCellphone/>
			break;
		case 'instagram':
			return <Instagram/>
			break;
		case 'twitter':
			return <Twitter/>
			break;
		case 'linkedin':
			return <LinkedIn/>
			break;
		case 'facebook':
			return <Facebook/>
			break;
	}

}
