const development =  [
			{
				name: 'Meu Painel',
				key: '/',
				icon: 'dashboard',
				path: '/'
			},
			{
				name: 'Assinaturas',
				key: 'email-signatures-sub',
				icon: 'mail',
				items: [
					{name: "Ver todas", key: '/email-signatures', path: '/email-signatures',},
					{name: "Criar Nova", key: '/email-signatures/create', path: '/email-signatures/create',},
				]
			},
			{
				name: 'Cartão de Visitas',
				key: 'business-cards-sub',
				icon: 'idcard',
				items: [
					{name: "Ver todos", key: '/business-cards', path: '/business-cards'},
					{name: "Criar Novo", key: '/business-cards/create', path: '/business-cards/create'},
				]
			},
			{
				name: 'Papel Timbrado',
				key: 'legal-paper-sub',
				icon: 'file',
				items: [
					{name: "Ver todos", key: '/legal-paper', path: '/legal-paper'},
					{name: "Criar Novo", key: '/legal-paper/create', path: '/legal-paper/create'},
				]
			}
];

const production =  [
			{
				name: 'Meu Painel',
				key: '/',
				icon: 'dashboard',
				path: '/'
			},
			{
				name: 'Assinaturas',
				key: 'email-signatures-sub',
				icon: 'mail',
				items: [
					{name: "Ver todas", key: '/email-signatures', path: '/email-signatures',},
					{name: "Criar Nova", key: '/email-signatures/create', path: '/email-signatures/create',},
				]
			},
			{
				name: 'Cartão de Visitas',
				key: 'business-cards-sub',
				icon: 'idcard',
				items: [
					{name: "Ver todos", key: '/business-cards', path: '/business-cards'},
					{name: "Criar Novo", key: '/business-cards/create', path: '/business-cards/create'},
				]
			},
];


export {
	development,
	production
}