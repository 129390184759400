import React from 'react';
import 'assets/scss/dashboard.scss';

import { Statistic, Icon, Col, Row } from 'antd';

function Overview(props) {

	const {data} = props;

	console.log(data);

	return (
		<div className="overview-main">

			<Row gutter={24}>
				{(data && data.stats && data.stats.signatures) ? (
					<Col span={8}>
					  				<Statistic
					  	            title="Assinaturas Ativas"
					  	            value={data.stats.signatures}
					  	            valueStyle={{ color: '#961C38' }}
					  	            prefix={<Icon type="mail" />}
					  	          />
					</Col>
				) : null}
				
					<Col span={8}>
					  				<Statistic
					  	            title="Cartões"
					  	            value={'Em breve'}
					  	            valueStyle={{ color: '#961C38' }}
					  	            prefix={<Icon type="idcard" />}
					  	          />
					</Col>
					<Col span={8}>
					  				<Statistic
					  	            title="Papéis Timbrados"
					  	            value={'Em breve'}
					  	            valueStyle={{ color: '#961C38' }}
					  	            prefix={<Icon type="file" />}
					  	          />
					</Col>
				
			</Row>
						
		</div>
	);
}

export default Overview;