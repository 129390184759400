import validator from 'validator';
import _ from 'lodash';

const FormValidate = async (data) => {
		const {basicInfo, contacts} = data;

		console.log(data);

		return new Promise((resolve, reject) => {
			if(!_.get(basicInfo, 'name') || basicInfo.name == '') reject('Por favor, insira um nome válido.');
			if(!_.get(basicInfo, 'lastName') || basicInfo.lastName == '') reject('Por favor, insira um sobrenome válido.');
			if(!_.get(contacts, 'phone') || contacts.phone == '') reject('Por favor, insira um telefone válido.');
			if(!_.get(contacts, 'site') || contacts.site == '' || !validator.isURL(contacts.site)) reject('Por favor, insira um site válido.');
			if(!_.get(contacts, 'email') || contacts.email == '' || !validator.isEmail(contacts.email)) reject('Por favor, insira um e-mail válido.');
			resolve(true);
		})

}

export {
	FormValidate
}