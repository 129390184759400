import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Result } from "antd";
import axios from 'axios';

const { Title } = Typography;

class Payment extends React.Component {

	state = {
		result: null,
		error: null
	}

	getData = async () => {

		const {params} = this.props.match;
		const {id} = params;

		if(params && id) {
			try {
				let response = await axios.post(`${window.api}/subscription/checkReturn`, {token: id}, {auth: true});
				if(response && response.data && response.data.result) {
					this.setState({result: true});
				} else {
					this.redirect();
				}
			} catch (e) {
				this.redirect();
			}
		} else {
			this.redirect();
		}

		
	}

	componentDidMount() {
		this.getData();
	}

	redirect = () => {
		this.props.history.replace('/not-found');
	}

	render() {

		return (
			<Wrapper>
				<WrapperInset>

					{this.state.result ? (
						<Result
						  status="success"
						  title="Assinatura criada com sucesso!"
						  subTitle="Assim que a operadora confirmar sua assinatura, seu plano será alterado."
						  extra={[
						    <Button type="primary" key="console" href="/settings">
						      Voltar às minhas Configurações
						    </Button>,
						    <Button key="buy" href="/email-signatures/create">Criar Assinatura</Button>,
						  ]}
						/>
					) : null}
				
				</WrapperInset>
			</Wrapper>
		);
	}
}
export default Payment;
