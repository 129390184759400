import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Upload } from "antd";
import {Spinner} from 'components/Misc/Spinner';
import {ColorPicker} from 'components/Misc/ColorPicker';
import { Row, Col, Icon, Modal } from "antd";
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {isEmail} from 'validator';
import User from 'stores/user';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from "mobx-react";

const { Option } = Select;
const { Title } = Typography;


@observer
class Company extends React.Component {

	state = {
		data: {},
		loading: false
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		});
	}


	sendError = (message) => {
		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});
	}

	save = async () => {

		const {data} = this.state;

		if(!this.state.loading) {

			if(!data.actual || data.actual == '') return this.sendError('Por favor, informe a senha atual.');
			if(!data.new || data.new == '') return this.sendError('Por favor, informe uma senha nova.');
			if(!data.new2 || data.new2 == '') return this.sendError('Por favor, confirme a senha nova.');
			if(data.new !== data.new2) return this.sendError('As senhas devem ser iguais.');

			this.setState({loading: true}, async () => {

			try {
				
					let response = await axios.post(`${window.api}/user/update/password`, data, {
					    auth: true
					});

					this.setState({loading: false}, () => {
						if(response && response.data && response.data.result) {
							this.setState({loading: false, data: {}}, () => {
								Swal.fire(
								  'Dados Salvos',
								  'Seus dados foram salvos com sucesso!',
								  'success'
								);
							})
						} else {
							Swal.fire({
								title: 'Erro',
								text: response.data.errorMessage ? response.data.errorMessage : 'Não foi possível alterar sua senha no momento.',
								type: 'error',
								confirmButtonText: 'OK'
							});
						}
					})
				


			} catch(e) {

				this.setState({loading: false}, () => {
					Swal.fire({
						title: 'Erro',
						text: 'Não foi possível alterar sua senha no momento.',
						type: 'error',
						confirmButtonText: 'OK'
					});
				});

				
			}

		});

		}

	}

	render() {


		return (
			<div style={{marginTop: 40,}}>

				<Form>

				<Row gutter={40} style={{display: 'flex'}}>
					<Col className="gutter-row" span={12} gutter={32}>
						<div className="gutter-box">


								<Row gutter={16}>
									<Col span={24}>
										<Form.Item label="Senha Atual:">
											<Input 
											size={"large"}
											value={this.getValue('data.actual')} 
											id={'actual'} 
											onChange={this.onChangeData}
											type={'password'}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item label="Nova Senha:">
											<Input 
											size={"large"}
											value={this.getValue('data.new')} 
											id={'new'} 
											onChange={this.onChangeData}
											type={'password'}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item label="Confirme a Nova Senha">
											<Input 
											size={"large"}
											value={this.getValue('data.new2')} 
											id={'new2'} 
											onChange={this.onChangeData}
											type={'password'}
											/>
										</Form.Item>
									</Col>
								</Row>

								<Divider/>

								<div style={{textAlign: 'right'}}>
									<Button 
									type="primary" 
									htmlType="submit" 
									onClick={this.save}
									loading={this.state.loading}
									>
									  Salvar
									</Button>
								</div>

						</div>
					</Col>

					


				</Row>

				

				</Form>
			</div>
		);
	}
}



export default Company;	
