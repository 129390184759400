import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Auth from 'functions/auth';
import {Spinner} from 'components/Misc/Spinner';

class PrivateRoute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logged: false,
      loginRedirect: false
    };
  }

  componentDidMount = async () => {
    Auth.check().then(() => {
      this.setState({logged: true});
    }, () => {
      this.setState({loginRedirect: true});
    })
  }

  render() {


    return (
      <Route
        render={props => {

        if(this.state.loginRedirect) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }

        if(!this.state.logged) {
          return (
            <div className="loading-view">
              <div className="spinner-wrapper">
                <Spinner color={'#EE4540'} size={60}/>
              </div>
            </div>
          );
        }

        let Component = this.props.component;
        return <Component {...props}/>

        }}
      />
    )
  }
}

export default PrivateRoute;