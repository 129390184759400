import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import User from 'stores/user';
import axios from 'axios';

class Auth {
    constructor() {

      this.setCookie = async(data) => {
          return new Promise((resolve, reject) => {
              Cookies.set('_pixelmaker-token', data.token, {expires: 7});
              resolve(true);
          });
      }

      this.set = async(data) => {

          return new Promise((resolve, reject) => {
              User.data = data;
              this.setCookie(data).then(() => {
                resolve(true);
              })
          });
      }



      this.isAuthenticated = () => {
        let cookie = Cookies.get('_pixelmaker-token');
        return cookie ? true : false;
      }

      this.check = async () => {

        return new Promise((resolve, reject) => {

            let cookieToken = Cookies.get('_pixelmaker-token');

            if(cookieToken) {

              axios.post(`${window.api}/auth/verify`, {
                token: cookieToken
              })
              .then((response) => {

                if(response.data.result) {
                  User.data = {
                    ...response.data.user,
                    token: cookieToken
                  };
                  resolve(true);
                } else {
                  reject(true)
                }
              })
              .catch((error) => {
                console.log(error);
                reject(true);
              });

            } else {
              reject(true);
            }

        })

      }

      this.logout = async(data) => {
          return new Promise((resolve, reject) => {
              Cookies.remove('_pixelmaker-token');
              localStorage.removeItem('userdata');
              resolve(true);
          });
      }

    }
}
const auth = new Auth();
export default auth;