import React from "react";


// FUNCTIONS
import {FormValidate} from './functions/validate';
import {Preview} from './functions/preview';
import _ from 'lodash';
import Swal from 'sweetalert2';
import User from 'stores/user';
import axios from 'axios';


// UI
import { Row, Col, Icon } from "antd";
import AppStyles from 'styles/main';
import {Spinner} from 'components/Misc/Spinner';
import FormView from './components/form';
import PreviewView from './components/preview';
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography } from "antd";
import signatureIcon from 'assets/images/signature-icon.svg';

// CSS
import "assets/scss/base.scss";
import "assets/scss/email-signatures.scss";

// UI COMP.
const { Title } = Typography;

class Signs extends React.Component {

	state = {
		data: {},
		loading: false,
		preview: null
	}


	constructor(props) {
	  super(props);
	  this.debouncePreview = _.debounce(this.preview, 500);
	}



	getData = async () => {
	  	try {

	  		let responseLayouts = await axios.get(`${window.api}/signatures/layouts/`, {auth: true});

	  		if(responseLayouts 
	  		   && responseLayouts.data 
	  		   && responseLayouts.data.result 
	  		   && responseLayouts.data.data 
	  		   && responseLayouts.data.data.length) {
	  			const {data} = responseLayouts.data;


	  			let userInitialData = {
	  				uid: User.getData()._id,
	  				layout: {
	  					showQrCode: true,
	  					id: data[0].id
	  				}
	  			};

	  			console.log(userInitialData);

	  			this.setState({layouts: data, loading: false, data: userInitialData}, () => {
	  				this.preview();
	  			});

	  		} else {
	  			this.sendError('Não foi possível carregar as informações no momento.');
	  		}

	  	} catch(e) {
	  		this.sendError('Não foi possível carregar as informações no momento.');
	  	}
	}

	preview = () => {

		if(this.shouldViewPreview()) {
			this.setState({preview: null, previewError: null}, () => {
				Preview(this.state.data).then(image => {
					this.setState({preview: `data:image/png;base64,${image}`});
				}, () => {
					this.setState({previewError: true});
				});
			});
		}

		
	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		}, () => {
			this.debouncePreview();
		});
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	save = () => {

		const {data} = this.state;
		

		FormValidate(data).then( async () => {
			try {
				let response = await axios.post(`${window.api}/signatures/add`, data, {auth: true});

				if(response && response.data && response.data.result) {
					const {id} = response.data;
					this.props.history.push(`/email-signatures/edit/${id}`);
				} else {
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch(e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}

		}, (err) => {
			return Swal.fire({
				title: 'Erro',
				text: err,
				type: 'error',
				confirmButtonText: 'OK'
			});
		})

	}

	shouldViewPreview = () => {


		if(this.getValue('data.basicInfo.name')
		   && this.getValue('data.basicInfo.lastName')
		   && this.getValue('data.contacts.site')
		) {
			return true
		}

		return false;

	}

	componentDidMount = this.getData();

	changeState = async (path, value) => {
		return new Promise((resolve, reject) => {
			this.setState(state => {
				_.set(state.data, path, value);
				return state;
			}, () => {
				resolve(true);
			});
		})
	}


	render() {

		if(this.state.loading) {
			return (
				<Wrapper>
					<WrapperInset>
						<Spinner color={AppStyles.colors[1]}/>
					</WrapperInset>
				</Wrapper>
			)
		}


		let formViewProps = {
			getValue: this.getValue,
			changeState: this.changeState,
			debouncePreview: this.debouncePreview,
			onChangeData: this.onChangeData,
			state: this.state,
			save: this.save
		};


		return (

				<Wrapper>
					<WrapperInset>
						<div className={"wrapper-title"}>
							<Title className="title">Criar Assinatura</Title>
						</div>

						<Row gutter={24} style={{display: 'flex'}}>
							<Col className="gutter-row" span={12}>
								<div className="gutter-box">
									<FormView
										{...formViewProps}
										create={true}
									/>
								</div>
							</Col>

							<Col className="gutter-row" span={12} gutter={"30"}>
									{this.shouldViewPreview() ? (
										<PreviewView
										state={this.state}
										params={this.props.match.params}
										/>
									) : (
										
										<div className="waitForPreview">
											<img src={signatureIcon} alt=""/>
											<span>Por favor, preencha os dados antes de pré visualizar sua assinatura.</span>
										</div>

									)}
							</Col>
						</Row>
					</WrapperInset>
				</Wrapper>

		);
	}
}

export default Signs;
