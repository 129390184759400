import React from 'react';
import {Table, Tag, Popconfirm } from 'antd';
import { Link } from "react-router-dom";
import * as Functions from 'functions/global';
import moment from 'moment';

class TableView extends React.Component {

	state = {
		dataSource: null,
		columns: null
	}

	componentDidMount() {

		console.log(this.props);
	  
		if(this.props.data) {

			let dataSource = new Array();
			this.props.data.map((item, index) => {
				const {basicInfo, contacts} = item;
				dataSource.push({
					...basicInfo,
					email: contacts.email,
					key: item._id,
					date: item.createdAt,
					status: item.status
				});
			});

			const columns = [
			  {
			    title: 'Nome',
			    dataIndex: 'name',
			    key: 'name',
			  },
			  {
			    title: 'Sobrenome',
			    dataIndex: 'lastName',
			    key: 'age',
			  },
			  {
			    title: 'E-mail',
			    dataIndex: 'email',
			    key: 'email',
			  },
			  {
			    title: 'Criado em',
			    dataIndex: 'date',
			    key: 'createdAt',
			    render: props => {
			    	return moment(props).format('DD/MM/YYYY')
			    }
			  },
			  {
			    title: 'Status',
			    dataIndex: 'status',
			    key: 'status',
			    render: props => {
			    	let status = Functions.signatures.getStatus(props.status);
			    	return (<Tag color={status.color} key={props.dataIndex}>{status.label.toUpperCase()}</Tag>)
			    }
			  },
			  {
			    title: 'Opções',
			    dataIndex: 'options',
			    key: 'options',
			    render: (props, row) => (
			    	<span>
			    		  <Link to={'business-cards/edit/'+row.key} style={{marginRight: 10}}>Editar</Link>
			    		  <Popconfirm 
			    		  title="Tem certeza que deseja remover?" 
			    		  okText="Sim" 
			    		  cancelText="Não"
			    		  onConfirm={() => this.props.removeItem(row.key)}
			    		  >
			    		    <a href="#">Remover</a>
			    		  </Popconfirm>
			    	</span>
			    )
			  },
			];

			this.setState({dataSource: dataSource, columns: columns});
		}

	}



	render() {	
		if(!this.state.dataSource) {
			return null
		}
		return ( <Table 
			dataSource={this.state.dataSource} 
			columns={this.state.columns}
			onRemove={(id) => console.log(id)}
			/>);
	}	
}

export {
	TableView
}