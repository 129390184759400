import React from 'react';
import {Table, Tag, Popconfirm } from 'antd';

class TableSignatures extends React.Component {
	render() {	

		const columns = [
		  {
		    title: 'Nome',
		    dataIndex: 'name',
		    key: 'name',
		  },
		  {
		    title: 'Sobrenome',
		    dataIndex: 'lastName',
		    key: 'age',
		  },
		  {
		    title: 'E-mail',
		    dataIndex: 'email',
		    key: 'email',
		  },
		  {
		    title: 'Opções',
		    dataIndex: 'id',
		    key: 'options',
		    render: props => (
		    	<span>
		    		  <a href={`/email-signatures/edit/${props}`} style={{marginRight: 10}}>Editar</a>
		    	</span>
		    )
		  },
		];

		return ( <Table dataSource={this.props.data.signatures} columns={columns} pagination={false}/>);
	}	
}

export {
	TableSignatures
}