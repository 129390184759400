import React from 'react';
import 'assets/scss/wrapper.scss';
import { Layout } from 'antd';
const { Content } = Layout;

class Wrapper extends React.Component {

	render() {
		return (
			<Layout className="wrapper-view">
				{this.props.children}
			</Layout>
		)
	}
}

class WrapperInset extends React.Component {

	render() {
		return (
			<Content className="wrapper-inset">
				{this.props.children}
			</Content>
		)
	}
}

export {
	Wrapper,
	WrapperInset
}

