import React from "react";
import { Link } from "react-router-dom";
import { Wrapper, WrapperInset } from "components/Wrapper/Wrapper";
import { Typography, Button, Form, Input, Select, Switch, Affix, Radio } from "antd";
import _ from 'lodash';
import Swal from 'sweetalert2';
import FormView from './components/form';
import {FormValidate} from './functions/validate';
import User from 'stores/user';
import axios from 'axios';

import { Row, Col, Icon } from "antd";
import "assets/scss/base.scss";
import "assets/scss/email-signatures.scss";
import {Preview} from './functions/preview';
import FileSaver from 'file-saver';

const { Title } = Typography;
const { Option } = Select;

class Signs extends React.Component {

	state = {
		data: {},
		loading: false,
		preview: null,
		previewTab: 'front'
	}

	constructor(props) {
	  super(props);
	  this.debouncePreview = _.debounce(this.preview, 500);
	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		}, () => {
			this.debouncePreview();
		});
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	changeState = async (path, value) => {
		return new Promise((resolve, reject) => {
			this.setState(state => {
				_.set(state.data, path, value);
				return state;
			}, () => {
				resolve(true);
			});
		})
	}

	preview = () => {
		this.setState({preview: null, previewError: null}, () => {
			Preview(this.state.data).then(image => {
				this.setState({preview: image});
			}, () => {
				this.setState({previewError: true});
			});
		});
	}


	getData = async () => {


		const {params} = this.props.match;
		const {id} = params;


		if(id) {
			try {
				let responseLayouts = await axios.get(`${window.api}/business-cards/layouts/`, {auth: true});
				let response = await axios.post(`${window.api}/business-cards/get/${id}`, {uid: User.get("_id")}, {auth: true});

				console.log(response);
				
				if(responseLayouts 
				   && responseLayouts.data 
				   && responseLayouts.data.result 
				   && responseLayouts.data.data 
				   && responseLayouts.data.data.length 
				   && response 
				   && response.data 
				   && response.data.result) {

					const {data} = response.data;

					this.setState({data: data, layouts: responseLayouts.data.data}, () => {
						this.preview();
					});

				} else {
					this.sendError('Não foi possível carregar as informações no momento.');
				}
			} catch (e) {
				this.sendError('Não foi possível carregar as informações no momento.');
			}
		} else {
			this.sendError('Não foi possível carregar as informações no momento.');
		}

	}

	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	save = async () => {

		const {data} = this.state;
		
		FormValidate(data).then(() => {

			this.setState({loading: true}, async () => {
					const {params} = this.props.match;
					try {
						let response = await axios.post(`${window.api}/business-cards/change/${params.id}`, data, {auth: true});

						if(response && response.data && response.data.result) {
							
							this.setState({loading: false}, () => {
								Swal.fire(
								  'Dados Salvos',
								  'Seus dados foram salvos com sucesso!',
								  'success'
								);
							})

						} else {
							this.setState({loading: false}, () => {
								Swal.fire({
									title: 'Erro',
									text: 'Não foi possível salvar as informações no momento.',
									type: 'error',
									confirmButtonText: 'OK'
								});
							})
						}
					} catch(e) {
						this.setState({loading: false}, () => {
							Swal.fire({
								title: 'Erro',
								text: 'Não foi possível salvar as informações no momento.',
								type: 'error',
								confirmButtonText: 'OK'
							});
						})
					}
			});
		}, (error) => {
			this.sendError(error);
		})
		
	}

	componentDidMount = this.getData();

	downloadPdf = async () => {

		const {params} = this.props.match;
		const {id} = params;

		axios.get(`${window.api}/business-cards/download/${id}`, {auth: true, responseType: 'blob'})
		  .then((response) => {
		    console.log(response);

		    let blob = new Blob([response.data], { type: 'application/zip' });
		    FileSaver.saveAs(blob, "cartao.zip");
		    



		  })
		  .catch((error) => {
		    console.log(error);
		  });



	}

	render() {

		let formViewProps = {
			getValue: this.getValue,
			changeState: this.changeState,
			debouncePreview: this.debouncePreview,
			onChangeData: this.onChangeData,
			save: this.save,
			state: this.state
		};

		return (

				<Wrapper>
					<WrapperInset>
						<div className={"wrapper-title"}>
							<Title className="title">Editar Cartão de Visita</Title>
						</div>

						<Row gutter={24} style={{display: 'flex'}}>
							
							<FormView
							{...formViewProps}
							/>

							<Col className="gutter-row" span={12} gutter={"10"}>
									<Title level={3} className="title">
										Pré-visualização
									</Title>

									<div className="biz-card-preview">

										<div className="biz-card-preview-tabs">
											<Radio.Group defaultValue="front" buttonStyle="solid" onChange={(val) => {
												this.setState({previewTab: val.target.value})
											}}>
											  <Radio.Button value="front">Frente</Radio.Button>
											  <Radio.Button value="back">Verso</Radio.Button>
											</Radio.Group>
										</div>

										{this.state.previewTab === 'front' ? (
											<div className="biz-card-preview-tab">
												{(this.state.preview && this.state.preview.front) ? (
													<div className="card-holder">
														<img src={`data:image/png;base64,${this.state.preview.front}`} alt=""/>
													</div>
												) : null}
											</div>
										) : null}
										{this.state.previewTab === 'back' ? (
											<div className="biz-card-preview-tab">
												{(this.state.preview && this.state.preview.back) ? (
													<div className="card-holder">
														<img src={`data:image/png;base64,${this.state.preview.back}`} alt=""/>
													</div>
												) : null}
											</div>
										) : null}

										<div className='biz-card-action-button'>
											<Button type="primary" onClick={this.downloadPdf}>Fazer Download</Button>
										</div>

									</div>
							</Col>
						</Row>
					</WrapperInset>
				</Wrapper>

		);
	}
}

export default Signs;
