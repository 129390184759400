import React from 'react';
import Auth from 'functions/auth';
import User from 'stores/user';
import { observer } from "mobx-react";
import _ from 'lodash';
import { Link, NavLink } from "react-router-dom";
import 'assets/scss/header.scss';
import { Layout, Avatar, Menu, Dropdown, Input, Icon } from 'antd';
import axios from 'axios';

const { Header } = Layout;




@observer
class HeaderView extends React.Component {

	state = {
		searchTerm: null,
		searchData: null
	}

	constructor(props) {
	  super(props);
	  this.debounceSearch = _.debounce(this.search, 500);
	  this.setWrapperRef = this.setWrapperRef.bind(this);
	  this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
	 	document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
    	document.removeEventListener('mousedown', this.handleClickOutside);
  	}

  	setWrapperRef(node) {
  	    this.wrapperRef = node;
  	}

  	handleClickOutside = (event) => {
  		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  		      this.setState({searchData: null, searchLoading: false});
  		}
  	}

	logout = (e) => {
		e.preventDefault();
		Auth.logout().then(() => {
			this.props.history.replace('/login');
		});
	}

	submenu = (props) => {
		return (
			<Menu>
			    <Menu.Item>
			    	<Link to={'/settings'}>
			    		Configurações
			    	</Link>
			    </Menu.Item>
			    <Menu.Item>
			      <a target="_blank" rel="noopener noreferrer" onClick={this.logout}>
			       Sair
			      </a>
			    </Menu.Item>
			  </Menu>
		);
	}


	search = async (val) => {
		if(this.state.searchTerm && this.state.searchTerm != '') {
			try {
				let response = await axios.post(`${window.api}/search`, {term: this.state.searchTerm}, {auth: true});
				if(response.data && response.data.result && response.data.dataSource) {
					this.setState({searchData: response.data.dataSource, searchLoading: false});
				} else {
					this.setState({searchData: null, searchLoading: false});
				}
			} catch(e) {

			}
		}
	}


	render() {

		const {searchData} = this.state;

		return (
	      <Header
	      className={'header'}
	      >
	      	<div className="header-view">

	      		<div className="search-view">
	      			<Input
	      			prefix={(<Icon type={'search'}/> )}
	      			suffix={this.state.searchLoading ? (<Icon type={'loading'}/>) : null}
	      			size={'large'}
	      			placeholder="Buscar assinaturas, cartões..."
	      			style={{border: 0}}
	      			allowClear
	      			value={this.state.searchTerm}
	      			onChange={(e) => {
	      				e.persist();
	      				if(e.target.value && e.target.value != '') {
	      					this.setState({searchTerm: e.target.value, searchLoading: true}, () => {
	      						this.debounceSearch(e.target.value);
	      					});
	      				} else {
	      					this.setState({searchTerm: e.target.value, searchLoading: false, searchData: null});
	      				}
	      			}}
	      			/>


	      			{searchData ? (
	      				<div className="search-results">
	      					{(searchData && searchData.length) ? (
	      						<div className="search-results-view" ref={this.setWrapperRef}>
	      							
	      								{searchData.map((section, sectionIndex) => {
	      									return (
	      										<div key={`searchsection_${sectionIndex}`} className="search-results-section">
	      											<div className="search-section-title">
	      												<span>{section.title}</span>
	      											</div>
	      											<div className="search-section-children">
	      												{section.children &&
	      												section.children.length &&
	      												section.children.map((item, itemIndex) => {
	      													return (
	      														<div key={`searchitem_${sectionIndex}_${itemIndex}`} className="search-section-children-item">
	      															<a href={item.path}>{item.title}</a>
	      														</div>
	      													);
	      												})}
	      											</div>
	      										</div>
	      									);
	      								})}

	      						</div>
	      					) : null}
	      				</div>
	      			) : null}
	      		</div>

	      		<div className="user-info">
	      			 <span className="user-name"><strong>{User.get('name')}</strong></span>
	      			 <Dropdown overlay={this.submenu()}>
	      			 	<div className="user-avatar">
	      			 		<Avatar size="large" icon="user" src={User.getAvatarURL()}/>
	      			 	</div>
	      			 </Dropdown>
	      			 
	      		</div>
	      	</div>
	      </Header>
		)
	}
}

export default HeaderView;