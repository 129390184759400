import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

function ColorPicker(props) {

	const [color, setColor] = useState(props.color ? props.color : '#000');
	const [visible, setVisible] = useState(false);

	return (
		<div className="colorPicker">
			<div className="colorPickerCurrent" onClick={() => setVisible(true)}>
				<div className="colorPickerCurrentColor">
					<span style={{backgroundColor: color}}></span>
				</div>
				<span className="colorPickerCurrentCode">{color}</span>
			</div>
			{visible ? (
				<div className="colorPickerHolder">
					<div className="colorPickerCover" onClick={() => setVisible(false)}></div>
					<SketchPicker
						disableAlpha
						color={color}
						presetColors={[]}
						onChange={(code) => {
							setColor(code.hex);
						}}
						onChangeComplete={code => {
							if(props.onChange) {
								props.onChange(code.hex);
							}
						}}
					/>
				</div>
			) : null}
		</div>
	);
}

export {
    ColorPicker
};
