import React from "react";

import { Form, Icon, Input, Button, Checkbox } from "antd";
import axios from "axios";
import {Spinner} from 'components/Misc/Spinner';
import Icone from './icons';

class Vcard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			data: null
		};
	}

	getData = async () => {

		if(!this.props.match.params || !this.props.match.params.id) {
			window.location.href = 'https://pixelmaker.co';
		} else {
			const {id} = this.props.match.params;
			let response = await axios.post(`${window.api}/vcard`, {id: id});

			if(response && response.data && response.data.result) {
				const {data} = response.data;
				console.log(data);
				this.setState({data, loading: false});
			} else {
				this.setState({error: true, loading: false});
			}
		}

	}


	componentDidMount = this.getData();

	render() {

		const {data} = this.state;

		return (
			<div className="vcard">
				<div className="hero">
					<div className="vcard-wrapper">
						
						{this.state.loading ? (
							<div className="vcard-block">
									<div className="vcard-loading">
										<Spinner color={'#9B1E38'} size={50}/>
									</div>
							</div>
						) : (
							<div className="vcard-block">
									{data ? (
										<div className="basic-info">
											<h1>{`${data.basicInfo.name} ${data.basicInfo.lastName}`}</h1>
											<h2>
												{data.professional.role ? `${data.professional.role}` : null} 
												{data.professional.section ? ` / ${data.professional.section}` : null}
											</h2>
											{data.contacts && data.contacts.email ? (
												<a className={'email'} href={`mailto:${data.contacts.email}`}>{data.contacts.email}</a>
											) : null}

											<div className="phones">
												{data.contacts && data.contacts.phone ? (
													<div className="block">
														<Icone type={'phone'}/>
														<div className="block-wrapper">
															<span>Telefone</span>
															<h4>{data.contacts.phone}</h4>
														</div>
													</div>
												) : null}
												{data.contacts && data.contacts.cellphone ? (
													<div className="block">
														<Icone type={'cellphone'}/>
														<div className="block-wrapper">
															<span>Celular</span>
															<h4>{data.contacts.cellphone}</h4>
														</div>
													</div>
												) : null}
											</div>

											{data.contacts && data.contacts.site ? (
												<a className={'url'} href={`${data.contacts.site}`}>{data.contacts.site}</a>
											) : null}
										</div>
									) : null}

									<div className="location-social">

										{data.location ? (
											<div className="col">
												{data.location.name ? (<h3>{data.location.name}</h3>) : null}
												{data.location.address ? (<address>{data.location.address}</address>) : null}
											</div>
										) : null}

										{data.contacts ? (
											<div className="col">
												<h3>Redes Sociais</h3>
												<div className="social-list">
													{data.contacts.facebook ? (<a href={data.contacts.facebook}><Icone type={'facebook'}/></a>) : null}
													{data.contacts.instagram ? (<a href={data.contacts.instagram}><Icone type={'instagram'}/></a>) : null}
													{data.contacts.twitter ? (<a href={data.contacts.twitter}><Icone type={'twitter'}/></a>) : null}
													{data.contacts.linkedin ? (<a href={data.contacts.linkedin}><Icone type={'linkedin'}/></a>) : null}
												</div>
											</div>
										) : null}

									</div>

									{data.logo ? (
										<div className="company-logo">
											<img src={data.logo} alt=""/>
										</div>
									) : null}

									<div className="download">
										<a href={`${window.api}/vcard/download/${this.props.match.params.id}`}>Baixar Cartão de Contato</a>
									</div>
							</div>
						)}

					</div>
				</div>
			</div>
		);
	}
}

export default Vcard;
