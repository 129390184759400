import React from 'react';
import {Table, Tag, Popconfirm, Divider } from 'antd';
import { Link } from "react-router-dom";

class TableView extends React.Component {


	getRandom = () => {
		return Math.floor((Math.random() * 9999999999) + 1);
	}

	render() {	
		let dataSource = [];

		for (var i = 0; i < 10; i++) {
			dataSource = [...dataSource, {
		    key: this.getRandom(),
		    name: 'Lucas',
		    lastName: 'Veiga',
		    email: 'lucas@lucasveiga.com',
		    date: '20/06/2019'
		  }
		  ];
		}

		const columns = [
		  {
		    title: 'Nome',
		    dataIndex: 'name',
		    key: 'name',
		  },
		  {
		    title: 'Sobrenome',
		    dataIndex: 'lastName',
		    key: 'age',
		  },
		  {
		    title: 'E-mail',
		    dataIndex: 'email',
		    key: 'email',
		  },
		  {
		    title: 'Criado em',
		    dataIndex: 'date',
		    key: 'date'
		  },
		  {
		    title: 'Opções',
		    dataIndex: 'options',
		    key: 'options',
		    render: (props, row) => (
		    	<span>
		    		  <Link to={'legal-paper/edit/'+row.key} style={{marginRight: 10}}>Editar</Link>
		    		  <Divider type="vertical" />
		    		  <Popconfirm title="Tem certeza que deseja remover?" okText="Sim" cancelText="Não">
		    		    <a href="#" style={{marginRight: 10}}>Remover</a>
		    		  </Popconfirm>
		    		  <Divider type="vertical" />
		    		  <Link to={'legal-paper/edit/'+row.key} style={{marginRight: 10}}>Visualizar</Link>
		    		  <Divider type="vertical" />
		    		  <Link to={'legal-paper/edit/'+row.key} style={{marginRight: 10}}>Download</Link>
		    	</span>
		    )
		  },
		];

		return ( <Table dataSource={dataSource} columns={columns}/>);
	}	
}

export {
	TableView
}