import React from 'react';
import { Link } from "react-router-dom";
import {Wrapper, WrapperInset} from 'components/Wrapper/Wrapper';
import { Typography, Button, Form, Input, Select, Switch, Affix, Divider, Upload } from "antd";
import {Spinner} from 'components/Misc/Spinner';
import {ColorPicker} from 'components/Misc/ColorPicker';
import { Row, Col, Icon, Modal } from "antd";
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {isEmail} from 'validator';
import User from 'stores/user';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { observer } from "mobx-react";

const { Option } = Select;
const { Title } = Typography;


@observer
class Company extends React.Component {

	state = {
		data: null,
		loading: false,
		previewImage: null,
	}

	getData = async () => {

		console.log('getting');

		try {

			let response = await axios.get(`${window.api}/company/get`, {auth: true});

			console.log(response);
			
			if(response && response.data && response.data.result) {

				const {data} = response.data;
				this.setState({data: data});

			} else {

				if(response.data.err === 'no_company') {
					this.setState({data: {}});
				} else {
					Swal.fire({
						title: 'Erro',
						text: 'Não foi possível carregar as informações no momento.',
						type: 'error',
						confirmButtonText: 'OK'
					});
				}

			}

		} catch (e) {

			console.log(e);

			Swal.fire({
				title: 'Erro',
				text: 'Não foi possível carregar as informações no momento.',
				type: 'error',
				confirmButtonText: 'OK'
			});

		}

	}

	getBase64 = (file) => {
	  return new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
	  });
	}

	getValue = (path) => {
		return _.get(this.state, path);
	}

	onChangeData = (event) => {
		event.persist();
		this.setState(state => {
			_.set(state.data, event.target.id, event.target.value);
			return state;
		});
	}


	sendError = (message) => {

		return Swal.fire({
			title: 'Erro',
			text: message,
			type: 'error',
			confirmButtonText: 'OK'
		});

	}

	save = async () => {

		const {data} = this.state;

		if(!data.name || data.name == '') this.sendError('Por favor, informe um nome válido.');
		if(!data.phone || data.phone == '') this.sendError('Por favor, informe um telefone válido.');
		if(!data.address || data.address == '') this.sendError('Por favor, informe um endereço válido.');



		let fd = new FormData();
		if(this.logoInput.files[0]) {
			fd.append('logo', this.logoInput.files[0]);
		}
		fd.append('data', JSON.stringify(data));

		this.setState({loading: true}, async () => {

			try {



				let response = await axios.post(`${window.api}/company/update`, fd, {
					headers: {
					  'Content-Type': 'multipart/form-data'
					},
				    auth: true
				});


				if(response && response.data && response.data.result) {
					this.setState({loading: false}, () => {
						Swal.fire(
						  'Dados Salvos',
						  'Seus dados foram salvos com sucesso!',
						  'success'
						);
					})
				} else {

					Swal.fire({
						title: 'Erro',
						text: 'Não foi possível carregar as informações no momento.',
						type: 'error',
						confirmButtonText: 'OK'
					});
					
				}


			} catch(e) {

				console.log(e);

				Swal.fire({
					title: 'Erro',
					text: 'Não foi possível carregar as informações no momento.',
					type: 'error',
					confirmButtonText: 'OK'
				});

			}

		})


	}

	componentDidMount = this.getData();

	logoChanged = () => {

		if (this.logoInput.files && this.logoInput.files[0]) {
		    var reader = new FileReader();
		    reader.onload = (e) => {
		      this.setState({previewImage: e.target.result})
		    }
		    reader.readAsDataURL(this.logoInput.files[0]);
		  }
	}


	getUserLogo = () => {
		if(this.state.previewImage) {
			return this.state.previewImage;
		} else {
			if(this.getValue('data.logo.thumb')) {
				return `${window.env.cdn}/${this.getValue('data.logo.thumb')}`;
			} else {
				return null;
			}
		}
	}


	render() {

		if(!this.state.data) {
			return (<div className="loading-wrapper-inset">
				<Spinner size={50} color={'#EE4540'}/>
			</div>)
		}


		return (
			<div style={{marginTop: 40,}}>

				<Form>

				<Row gutter={40} style={{display: 'flex'}}>
					<Col className="gutter-row" span={12} gutter={32}>
						<div className="gutter-box">

							
								<Title level={3} className="title">
									Dados da Empresa
								</Title>

								<Row gutter={16}>
									<Col span={24}>
										<Form.Item label="Nome">
											<Input 
											size={"large"}
											value={this.getValue('data.name')} 
											id={'name'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item label="Endereço Completo">
											<Input 
											size={"large"}
											value={this.getValue('data.address')} 
											id={'address'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item label="Telefone">
											<Input 
											size={"large"}
											value={this.getValue('data.phone')} 
											id={'phone'} 
											onChange={this.onChangeData}
											/>
										</Form.Item>
									</Col>
								</Row>


						</div>
					</Col>

					<Col className="gutter-row" span={12} gutter={32}>

						<Title level={3} className="title">
							Logotipo da empresa
						</Title>

						<div className="image-selecter">

							{this.getUserLogo() ? (
								<div className="image-preview"><img src={this.getUserLogo()} alt=""/></div>
							) : null}

							<div className="button-holder">
								<button>Selecionar</button>
								<input 
								type="file" 
								name="logo" 
								onChange={this.logoChanged}
								ref={(ref) => this.logoInput = ref}
								/>
							</div>

							
						</div>



						<div style={{marginTop: 40, float:'left', width: '100%'}}>
							<Title level={3} className="title">
								Cores da empresa
							</Title>

							<div className="companyColors">
								<div className="companyColorCol">
									<span>Cor 1</span>
									<ColorPicker
									color={this.getValue('data.options.color1')}
									onChange={color => {
										this.setState(state => {
											_.set(state.data, 'options.color1', color);
											return state;
										});
									}}
									/>
								</div>	
								<div className="companyColorCol">
									<span>Cor 2</span>
									<ColorPicker
									color={this.getValue('data.options.color2')}
									onChange={color => {
										this.setState(state => {
											_.set(state.data, 'options.color2', color);
											return state;
										});
									}}
									/>
								</div>							
							</div>
						</div>

					</Col>


				</Row>

				<Divider/>

				<div style={{textAlign: 'right'}}>
					<Button 
					type="primary" 
					htmlType="submit" 
					onClick={this.save}
					loading={this.state.loading}
					>
					  Salvar
					</Button>
				</div>

				</Form>
			</div>
		);
	}
}



export default Company;	
