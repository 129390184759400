import React from 'react';
import 'assets/scss/dashboard.scss';
import {Wrapper} from 'components/Wrapper/Wrapper';
import { Card, Col, Row, Button, Skeleton } from 'antd';
import User from 'stores/user';
import {TableSignatures} from './table';
import {ChartViews} from './charts';
import Overview from './overview';
import GreetingsSVG from 'assets/images/dashboard-greetings.svg';
import Swal from 'sweetalert2';
import axios from 'axios';

class Dashboard extends React.Component {

	state = {
		data: null
	}

	componentDidMount = async () => {
	  try {

	  	let response = await axios.get(`${window.api}/user/dashboard/`, {auth: true});
	  	if(response && response.data && response.data.result) {
	  		const {data} = response.data;
	  		console.log(data);
	  		this.setState({data});
	  	} else {
	  		return Swal.fire({
	  			title: 'Erro',
	  			text: 'Não foi possível carregar as informações no momento.',
	  			type: 'error',
	  			confirmButtonText: 'OK'
	  		});
	  	}

	  } catch(e) {
	  	return Swal.fire({
	  		title: 'Erro',
	  		text: 'Não foi possível carregar as informações no momento.',
	  		type: 'error',
	  		confirmButtonText: 'OK'
	  	});
	  }
	}

	render() {

		return (
			<Wrapper>

			
					
						<Row gutter={24} style={{ marginBottom: 24 }}>

										      <Col span={12}>
										         <Card bordered={false} className={'card-block'}>
										         	<div className="dashboard-greentings">
										         		<h2><strong>Olá {User.get('name')},</strong> bem-vindo ao Pixelmaker.</h2>
										         		<span>Comece agora mesmo a criar!</span>
										         	</div>
										         	<div className="dashboard-image">
										         		<img src={GreetingsSVG} alt=""/>
										         	</div>

										         	<div style={{textAlign: 'center', borderTop: '1px solid #f1f1f1', paddingTop: 20}}>
										         		<Button href="/email-signatures/create" type="primary" size={'large'} style={{marginRight: 10}}>Criar Assinatura</Button>
										         		<Button href="/email-signatures" type="default" size={'large'}>Todas as Assinaturas</Button>
										         	</div>

										         </Card>
										      </Col>

										      <Col span={12}>
										        <Card title="Visão Geral" bordered={false} className={'card-block'}>
										          {!this.state.data ? (
										          	<Skeleton active title={false} />
										          ) : (
										          	<Overview data={this.state.data}/>
										          )}
										        </Card>   

										        <Card title="Últimas Assinaturas" bordered={false} className={'card-block'}>
										        	{!this.state.data ? (
										        		<Skeleton active title={false} paragraph={{ rows: 6 }} />
										        	) : (
										        		<TableSignatures data={this.state.data}/>
										        	)}
										    	  </Card>
										      </Col>

					     </Row>

										   
										
			</Wrapper>
		)
	}
}

export default Dashboard;